import connect from '@vkontakte/vk-connect';
import config from '../config';
import app from './app';
import { chunk, unique } from './utils';

import { usersGet } from './generate/methods/users';
import { groupsGet, groupsGetById } from './generate/methods/groups';
import { newsfeedSearch } from './generate/methods/newsfeed';

//получение токена пользователя
export function getToken(scope, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppAccessTokenReceived': //токен получен.
					successCallback(e.detail.data); 
					connect.unsubscribe(clb);
				break;
				case 'VKWebAppAccessTokenFailed': //токен не получен.
					errorCallback(true);
					connect.unsubscribe(clb);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetAuthToken", {
		"app_id": config.app_id, 
		"scope": scope.join(',')
	});
}

//получение токена по текущим правам доступа пользователя
export function getStartToken(scope, callback) {
	let newScope = [];

	console.log("scope", scope)

	scope.forEach((item) => {
		if ( item !== "menu" && item !== "notify") { //item !== "" && 
			newScope.push(item) 
		}
	})

	console.log("newScope", newScope)

	if (newScope.length > 0) {
		//проверяем параметры запуска
		if (+config.params.vk_is_app_user === 1) { //баг фикс запроса прав доступа в самом начале.
			getToken(newScope, (response) => {
				callback(response)
			}, (error)=> {
				callback(false);
			})
		} else {
			callback(false);
		}
	} else {
		getToken([""], (response) => {
			callback(response)
		}, (error)=> {
			callback(false);
		})
	}

}

//расширяет права доступа пользователя
export function getAddScope(scope, callback, callbackEror = () =>{}) {
	let { userScope } = app.state
	let newScope = [];
	let newScopeAdd = [];
	userScope.forEach((item) => {
		if ( item !== "") { // 
			newScope.push(item) 
		}
	})
	scope.forEach((item) => {
		if ( item !== "") { // 
			newScopeAdd.push(item) 
		}
	})

	newScope = [...newScope, ...newScopeAdd]

	getToken(unique(newScope), (response) => {
		callback(response)
	}, (error) => {
		callbackEror(true)
	})
}

//пользователь разрешает писать сообществу
export function getAllowMessagesFromGroup(gid, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppAllowMessagesFromGroupResult': //пользователь разрешил отправлять себе сообщения от имени группы
					successCallback(e.detail.data); 
					connect.unsubscribe(clb);
				break;
				case 'VKWebAppAllowMessagesFromGroupFailed': //отмена
					errorCallback(e.detail.data); 
					connect.unsubscribe(clb);
				break;
				default:
				//
			}
		}
	}

	connect.subscribe(clb);
	connect.send("VKWebAppAllowMessagesFromGroup", { "group_id": gid });
}

//вызывает окно оплаты VK Pay для оплаты
export function getVkPay(amount, description, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppOpenPayFormResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppOpenPayFormFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppOpenPayForm", {
		"app_id": config.app_id,
		"action": "pay-to-group",
		"params": {
			"amount": amount,
			"description": description,
			"group_id": config.group_id,
		}
	});
}

//получение информации о текущем пользователе
export function getUserInfo(callback) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppGetUserInfoResult':
					callback(e.detail.data);
				break;
				default:
					//
			}
		}
	}
	connect.subscribe(clb);
	connect.send('VKWebAppGetUserInfo', {});
}

//получение ключа доступа сообщества NEW
export function getCommunityAuthToken(gid, scope, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppGetCommunityTokenResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppGetCommunityTokenFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetCommunityToken", {"app_id": config.app_id, "group_id": gid, "scope": scope.join(',')});
}

//Предпросмотр виджета сообщества
export function getWidgetPreviewBox(gid, type, code, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppShowCommunityWidgetPreviewBoxResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppShowCommunityWidgetPreviewBoxFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppShowCommunityWidgetPreviewBox", {"group_id": gid, "type": type, "code": code});
}


//вызов нативного просмоторщика фотографий
//images - массив ссылок на фото
export function showImagesBox(images = [], start_index = 0, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppShowImagesFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppShowImages", { 
		start_index: start_index,
		images: images
	});
}

//выбор контакта из телефонной книги
export function getPhoneContacts(successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppContactsDone':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppContactsClosed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppOpenContacts", {});
}

//подписывается на обновления VKWebAppUpdateConfig
export function listenVKWebAppUpdateConfig(successCallback = () => {}) {
	// подписываемся на VKWebAppUpdateConfig
	connect.subscribe((e) => {
		switch (e.detail.type) {
			case 'VKWebAppUpdateConfig':
				successCallback(e.detail.data)
			break;
			default:
				//
		}
	});
}

//Вызов списка друзей пользователя
export function getFriends(successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppGetFriendsResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppGetFriendsFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetFriends", {});
}

//iOS, Android
//Вызов камеры для сканирования QR 
export function webAppOpenQR(successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppOpenQRResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppOpenQRFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppOpenQR");
}

//подписывается на сообщество
export function webAppJoinGroup(group_id, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppJoinGroupResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppJoinGroupFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;

				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppJoinGroup", {"group_id": group_id});
}

/* VK API HELPERS*/
//получение данных о пользователях

/*
export function apiGetUser(access_token, uids, callback) {
	let arr = chunk(uids, 500);
	let arrToSend = [];
	forEachPromise(arr, (item) => {
		return new Promise((resolve, reject) => {
			try {
				usersGet(access_token, {
					user_ids: item.join(','),
					fields: config.userFields.join(',')
				}, (response) => {
					arrToSend.push(...response)
					resolve()
				}, (error) => {
					console.log(error)
				})
			} catch (err) {
				console.log('Ошибка users.get 584', err)
			}
		})
	}).then(() => {
		callback(arrToSend)
	});
}
*/

//получение данных о группах
export function apiGetGroupById(access_token, gids, callback) {
	let arr = chunk(gids, 300);
	__apiGetGroupById(access_token, arr, 0, [], (info) => {
		callback(info)
	})
}

function __apiGetGroupById(access_token, arr, i, info, callback) {
	if (i < arr.length) {
		groupsGetById(access_token, {
			group_ids: arr[i].join(','),
			fields: config.groupFields.join(',')
		}, (response) => {
			info.push(...response)

			if (arr.length === 1) {
				__apiGetGroupById(access_token, arr, i + 1, info, callback)
			}  else {
				setTimeout(() => {
					__apiGetGroupById(access_token, arr, i + 1, info, callback)
				}, 500)			
			}
		
		}, (error) => {
			console.log(error)
		})
	} else {
		callback(info)
	}
}

//получение данных о пользователях
export function apiGetUser(access_token, uids, callback) {
	let arr = chunk(uids, 300);
	__apiGetUser(access_token, arr, 0, [], (info) => {
		callback(info)
	})
}

function __apiGetUser(access_token, arr, i, info, callback) {
	if (i < arr.length) {

		usersGet(access_token, {
			user_ids: arr[i].join(','),
			fields: config.userFields.join(',')
		}, (response) => {
			info.push(...response)

			if (arr.length === 1) {
				__apiGetUser(access_token, arr, i + 1, info, callback)
			}  else {
				setTimeout(() => {
					__apiGetUser(access_token, arr, i + 1, info, callback)
				}, 500)			
			}
		
		}, (error) => {
			console.log(error)
		})
	} else {
		callback(info)
	}
}

//получение данных по недостающим пользователям 
// availableUids - массив пользователей которые уже есть 
// needUids - массив пользователей по которым нужно получить данные
export function getMissingUsers(availableUids = [], access_token, needUids = [], callback = () => {}) {
	let uids = [];

	needUids.forEach((uid) => {
		if (availableUids.indexOf(uid) === -1) {
			availableUids.push(uid)
			uids.push(uid)
		}
	})

	if (uids.length > 0) {
		apiGetUser(access_token, unique(uids), (apiResponse) => {
			callback(apiResponse)
		})
	} else {
		callback(false)
	}
}

//TODO Сделать получение всех групп
//получение групп пользователя
export function apiGetGroups(access_token, user_id, callback) {
	groupsGet(access_token, {
		user_id: user_id,
		extended: 1,
		fields: config.groupFields.join(','),
		offset: 0,
		count: 1000,
	}, (response) => {
		callback(response)		
	}, (error) => {
		console.log(error)
	})
}



//получение данных по недостающим группам 
// availableGids - массив групп которые уже есть 
// needGids - массив групп по которым нужно получить данные
export function getMissingGroups(availableGids = [], access_token, needGids = [], callback = () => {}) {
	let gids = [];

	needGids.forEach((gid) => {
		if (availableGids.indexOf(gid) === -1) {
			availableGids.push(gid)
			gids.push(gid)
		}
	})

	if (gids.length > 0) {
		apiGetGroupById(access_token, unique(gids), (apiResponse) => {
			callback(apiResponse)
		})
	} else {
		callback(false)
	}
}

//поиск с пагинацией
export function apiNewsfeedSearch(access_token, q = "", start_from = false, callback = () => {}) {
	if (start_from) {
		newsfeedSearch(access_token, {
			q: q,
			extended: 1,
			fields: config.userFields.join(','),
			start_from: start_from,	
		}, (response) => {
			callback(response)
		}, (error) => {
			console.log(error)
		})
	} else {
		newsfeedSearch(access_token, {
			q: q,
			extended: 1,
			fields: config.userFields.join(',')
		}, (response) => {
			callback(response)
		}, (error) => {
			console.log(error)
		})	
	}
}