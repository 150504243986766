import React from 'react';
import { Panel, PullToRefresh } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import CardOutlineContainer from '../components/CardOutline/CardOutlineContainer';
import CardOutline from '../components/CardOutline/CardOutline';
import InfoPlaceholder from '../components/InfoPlaceholder/InfoPlaceholder';
import SortFilter from '../components/SortFilter/SortFilter';
import Pagination from '../components/Pagination/Pagination';

import app from '../func/app';
import config from '../config';

import { getMissingGroups, getMissingUsers } from '../func/vk';
import { findObjectById, memberSort } from '../func/utils';
import { getProducts } from '../func/back';

import Icon56InfoOutline from '@vkontakte/icons/dist/56/info_outline';

class Catalog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fetching: false,
		};
	}

	formatCount = (count) => {
		return String(count).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	}
	
	declOfNum = (number, titles) => {
		const cases = [2, 0, 1, 1, 1, 2];  
		return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
	}

	get sortItems() {
		const { items, apiGroupsData, filter } = this.props
		const { member_count, amount } = config

		if (filter.subscribers || filter.activity || filter.amount || filter.ad_network !== null) { //фильтр применён

			let endItems = items.filter(({deleted}) => {
					if (!deleted) {
						return true
					} else {
						return false
					}
				})
			
			if (filter.subscribers) { //если включен фильтр по подписчикам
				let target = findObjectById(member_count, +filter.subscribers)
				let { min, max } = target

				endItems = endItems.filter(({gid}) => {
					const group = findObjectById(apiGroupsData, gid)
					if (+group.members_count >= min && +group.members_count <= max) {
						return true
					} else {
						return false
					}
				})
			} 
			
			if (filter.activity) { //если включен фильтр по категории
				endItems = endItems.filter(({activity}) => {
					if (+activity === +filter.activity) {
						return true
					} else {
						return false
					}
				})
			}

			if (filter.amount) { //если включен фильтр по категории
				let target = findObjectById(amount, +filter.amount)
				let { min, max } = target
				endItems = endItems.filter(({amount}) => {
					if (+amount >= min && +amount <= max) {
						return true
					} else {
						return false
					}
				})
			}			

			if (filter.ad_network !== null) { //если включен фильтр по категории
				endItems = endItems.filter(({ad_network}) => {
					if (ad_network === filter.ad_network) {
						return true
					} else {
						return false
					}
				})
			}

			return endItems.sort(memberSort('members_count', apiGroupsData))
		} else {
			return items.filter(({deleted}) => {
					if (!deleted) {
						return true
					} else {
						return false
					}
				}).sort(memberSort('members_count', apiGroupsData))
		}
	}

	onClickFilter = () => {
		app.setState((state) => ({
			filter: {
				...state.filter,
				open: !state.filter.open
			}
		}))
	}

	onChangeSelect = (e) => {
		const { name, selectedIndex, options } = e.currentTarget;
		app.setState((state) => ({
			filter: {
				...state.filter,
				[name]: +options[selectedIndex].value 
			},
			catalogInStr: 16,
		}))
	}

	onChangeSwitch = (e) => {
		const { name, checked } = e.currentTarget;
		app.setState((state) => ({
			filter: {
				...state.filter,
				[name]: checked
			},
			catalogInStr: 16,
		}))
	}

	onClickClearAll = () => {
		app.setState((state) => ({
			filter: {
				...state.filter,
				accept: false, //настройки фильтра применены
				subscribers: "", //сортировка по подписчикам
				activity: "", //сортировка по категории
				amount: "", //сортировка по цене
				ad_network: null, //сортировка по РСВК
			},
			catalogInStr: 16,
		}))
	}

	showNextPage = (callback) => {
		app.setState((state) => ({
			catalogInStr: state.catalogInStr + 16
		}), callback)
	}

	goProduct = (e) => {
		const { navigator } = this.props
		const { id } = e.currentTarget.dataset
		app.setState(() => ({
			feedTarget: +id
		}), () => {
			navigator.goPanel("ProductView")
		})
	}

	onRefresh = () => {
		const { apiUsersData,  apiGroupsData} = this.props
		this.setState({
			fetching: true,
		}, () => {
			getProducts((response) => {

				const { feed } = response
				const availableGids = apiGroupsData.map(({id}) => { return id })
				const availableUids = apiUsersData.map(({id}) => { return id })
				const needGids = feed.items.map(({gid}) => { return gid })
				const needUids = feed.items.map(({uid}) => { return uid })
				
				getMissingUsers(availableUids, config.access_token, needUids, (apiUsersData) => {
					getMissingGroups(availableGids, config.access_token, needGids, (apiGroupsData) => {

						app.setState((state) => ({
							feed: {...feed},
							filter: { // стандартные настройки фильтра (кроме open: false,)
								...state.filter,
								accept: false,
								subscribers: "",
								activity: "",
								amount: "",
								ad_network: null, 
							},
							catalogInStr: 16, //стандартное количество отображаемых продуктов на странице
							apiGroupsData: (apiGroupsData) ? [...state.apiGroupsData, ...apiGroupsData] : [...state.apiGroupsData], //новые данные по группам, если они вообще есть
							apiUsersData: (apiUsersData) ? [...state.apiUsersData, ...apiUsersData] : [...state.apiUsersData], //новые данные по пользователям, если они вообще есть
						}), () => {
							this.setState({fetching: false})
						})

					})
				})
				
			})
		})
	}

	render() {
		const { fetching } = this.state
		const { id, apiGroupsData, filter, catalogInStr, count } = this.props //navigator,
		return (
			<Panel theme="white" id={id}>
				<Header name="Каталог"/>
				<PullToRefresh onRefresh={this.onRefresh} isFetching={fetching}>
					<div>
						<SortFilter
							filter={filter}

							onClickFilter={this.onClickFilter}
							onChangeSelect={this.onChangeSelect}
							onChangeSwitch={this.onChangeSwitch}
							onClickClearAll={this.onClickClearAll}
						/>

						{
							(this.sortItems.length > 0) ? //сортировка дала 0 результатов
								<div>
									<CardOutlineContainer>
										{
											this.sortItems.map((item, i) => {
												const { id, gid, amount } = item
												const group = findObjectById(apiGroupsData, gid)
												return (i + 1 <= catalogInStr) ?
													<CardOutline
														key={id}
														id={id}
														img={group.photo_100}
														title={group.name}
														subscribers={this.formatCount(group.members_count)}
														description={this.declOfNum(+group.members_count, ['подписчик', 'подписчика', 'подписчиков'])}
														amount={`${this.formatCount(amount)} р.`}
														goProduct={this.goProduct}
													/>
												: null
											})
										}
									</CardOutlineContainer>

									<Pagination count={this.sortItems.length} navCount={catalogInStr} navOffset={0} showNextPage={this.showNextPage}/>
								</div>
							: 
								(count === 0) ?
									<InfoPlaceholder
										icon={<Icon56InfoOutline/>}
										title="Каталог"
										text="К сожалению, тут пока ещё нет объявлений"
									/>
								: 
									<InfoPlaceholder
										icon={<Icon56InfoOutline/>}
										title="Поиск"
										text="К сожалению, по вашему запросу ничего не найдено"
									/>
						}
					</div>
				</PullToRefresh>
			</Panel>
		)
	}
}

export default Catalog;