import React from 'react';
import { Cell, FormLayout, Select, Div, Switch, Button } from '@vkontakte/vkui';

import config from '../../config';

import './style.css';

import Icon24Dropdown from '@vkontakte/icons/dist/24/dropdown';

class SortFilter extends React.Component {
	render() {
		let { filter, onClickFilter, onChangeSelect, onChangeSwitch, onClickClearAll } = this.props
		
		return (
			<div>
				<Cell onClick={onClickFilter} indicator={<Icon24Dropdown className={(filter.open) ? `rotate-180` : ``}/>}>Параметры поиска</Cell>
				{
					(filter.open) ?
						<div>
							<FormLayout className="__FormLayoutBlock">
								<Select name="subscribers" onChange={onChangeSelect} value={filter.subscribers}  top="Количество учасников" placeholder="Выберите количество участников">
									{
										config.member_count.map((item) => {
											return <option key={item.id} value={item.id}>{item.name}</option>
										})
									}
								</Select>

								<Select name="activity" onChange={onChangeSelect} value={filter.activity} top="Тематика" placeholder="Выберите тематику">
									{
										config.activity.map((item) => {
											return <option key={item.id} value={item.id}>{item.name}</option>
										})
									}
								</Select>

								<Select name="amount" onChange={onChangeSelect} value={filter.amount} top="Цена" placeholder="Выберите цену">
									{
										config.amount.map((item) => {
											return <option key={item.id} value={item.id}>{item.name}</option>
										})
									}
								</Select>

								<Div className="pb-0 flex_start">
									<Switch checked={(filter.ad_network) ? true : false} name="ad_network" onChange={onChangeSwitch}/> 
									<div className="pl-10">РСВК подключена</div>
								</Div>
							</FormLayout>
							<Div className="flex_end">
								<Button onClick={onClickClearAll} level="secondary">Сбросить фильтр</Button>
							</Div>
						</div>
					: null
				}
			</div>
		);
	}
}

export default SortFilter;