import React from 'react';
import { Panel, Div, Button, Link } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import GroupHeader from '../components/GroupHeader/GroupHeader';

import app from '../func/app';
import config from '../config';
import { findObjectById, findObjectIndex } from '../func/utils';
import { webAppJoinGroup } from '../func/vk';

class Info extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			groupInfo: false,
		};
	}

	componentDidMount() {
		const { apiGroupsData } = this.props
		const { info_group_id } = config

		let groupInfo = findObjectById(apiGroupsData, info_group_id)

		this.setState((state) => ({
			groupInfo: groupInfo,
		}))
	}

	onSubscribe = () => {
		const { groupInfo } = this.state
		webAppJoinGroup(+groupInfo.id, (response) => {
			app.setState((state) => {
				const index = findObjectIndex(state.apiGroupsData, +groupInfo.id)
				return {
					apiGroupsData: (index !== false) ? 
						[...state.apiGroupsData.slice(0, index), {...state.apiGroupsData[index], is_member: 1}, ...state.apiGroupsData.slice(index + 1)]
					:
						[...state.apiGroupsData]
				}
			}, () => {
				this.setState((state) => ({
					groupInfo: {
						...state.groupInfo,
						is_member: 1
					}
				}))
			})

		}, (error) => {
			console.log(error)
		})
	}

	render() {
		const { groupInfo } = this.state
		const { id } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header name="Информация"/>
				{
					(groupInfo) ?
						<div>
							<GroupHeader 
								groupInfo={groupInfo}
								description="Factura Shop"
								actionBlock={
									<Div className="flex_end pt-0">
										<Button component="a" href={`https://vk.com/im?media=&sel=-${groupInfo.id}`} target="_blank" className="mr-5">Сообщение</Button>
										{
											(groupInfo.is_member) ?
												null
											: <Button onClick={this.onSubscribe}>Подписаться</Button>
										}
									</Div>
								}
							/>
							<Div>
								Привет, Друзья! Давайте знакомиться: Ян Блюмхен <Link href="https://vk.com/jan" target="_blank">vk.com/jan</Link> и Павел Щербатов <Link href="https://vk.com/pas" target="_blank">vk.com/pas</Link> - основатели агентства Factura-Smm.<br/>
								<br/>
								Занимаемся развитием и монетизацией сообществ с момента их появления во ВКонтакте и к настоящему моменту, с нуля нам удалось развить сеть с более чем 50млн аудиторией. На данный момент мы являемся партнерами ВК, помогаем тестировать новые инструменты и всегда в курсе грядущих изменений. Спасибо, что пользуетесь нашим сервисом!
							</Div>

						</div>
					: null
				}
			</Panel>
		)
	}
}

export default Info;