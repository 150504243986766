import React from 'react';
import { Root, Epic, Tabbar, TabbarItem } from '@vkontakte/vkui';
import { VKWebAppClose } from './vk'

class Stack extends React.Component {

	constructor(props) {
		super(props);
		const { activePage } = this.props

		this.state = {
			activePage: activePage, 	//текущая страница
			pages: {},					//объект со страницами и текущими панелями на них
			historyClick: 0,
			historyPanel: {}, 			//история переходов между панелями для каждой страницы.
			historyPage: [],			//история переходов между страницами
			...this.__init()			//иницирует
		};
	}

	__isKeyInObj = (obj, key) => {
		if (obj) {
			if (typeof obj[key] !== "undefined") {
				return obj[key]
			} else {
				return false
			}
		} else {
			return false
		}
	}

	/*
		Перехватываем событие нажатия на нативную кнопку назад.
		Иницируем первоначальный стейт
	*/
	__init = () => {
		const { children, activePage } = this.props
		let state = {}

		window.onpopstate = (e) => {
			this.__nativeGoBack()
			return;
		}

		React.Children.forEach(children, (Child) => {
			if (Child) {
				const { props } = Child
				const { id, activePanel } = props
				state.pages = {
					...state.pages,
					[id]:activePanel
				}
				state.historyPanel = {
					...state.historyPanel,
					[id]: (id === activePage) ? [activePanel] : []
				}
			}
		})
		return state
	}

	/*
		добавляет запись в History браузера
	*/
	__pushState = (state, title, url) => {
		window.history.pushState(state, title, url)
	}

	/*
		билдит таб бар, устанавливаем иконки и внутреннюю логику
	*/
	__buildBar = (children) => {
		const { activePage } = this.state
		return (
			<Tabbar>
				{React.Children.map(children, (Child, key) => (
					(Child) ?
						<TabbarItem
							key={key}
							onClick={this.goPage}
							selected={activePage === Child.props.id}
							data-page={Child.props.id}
							text={Child.props.title || ""}
							children={Child.props.icon || null}
						/>
					: null
				))}
			</Tabbar>
		);
	}

	/*
		добавляет запись к истории страницы
	*/
	__setHistoryPage = (page, callback = () => {}) => {
		const { activePage, pages, historyPanel } = this.state		

		if (activePage !== page) {
			this.__pushState({}, "", "")

			this.setState((state) => ({
				historyPage: [...state.historyPage, state.activePage],
				historyPanel: (historyPanel[page][historyPanel[page].length - 1] !== pages[page]) ? { ...state.historyPanel, [page]: [...state.historyPanel[page], pages[page]] } : { ...state.historyPanel },
				historyClick: state.historyClick + 1
			}), () => { 
				callback(true)
			})
		}
	}

	/*
		добавляет запись к истории панели
	*/
	__setHistoryPanel = (panel, callback = () => {}) => {
		const { historyPanel, activePage } = this.state

		if (historyPanel[activePage][historyPanel[activePage].length - 1] !== panel) {
			this.__pushState({}, "", "")
			this.setState((state) => ({
				historyPanel: {
					...state.historyPanel,
					[state.activePage]: [...state.historyPanel[state.activePage], panel]
				},
				historyClick: state.historyClick + 1
			}), () => { 
				callback(true)
			})
		}
	}

	/*
		вызывается при нажатии на нативную кнопку надзад
	*/
	__nativeGoBack = () => {
		const { historyPanel, historyPage, activePage, historyClick } = this.state
		
		if (historyClick < 1) {
			VKWebAppClose()
		} else {
			const historyThisPage = historyPanel[activePage] //история текущей страницы

			if (historyThisPage.length > 1) {
				//у этой страницы есть переходы просто переходим на прошлую панель в рамках текущей страницы
				this.goPanelBack()
			} else {
				//у этой страницы больше нет переходов
				//получаем предыдущую страницу
				const lastPage = historyPage[historyPage.length - 1] 
				const historyLastPage = historyPanel[lastPage] //история предыдущей страницы
				const lastPanel = historyLastPage[historyLastPage.length - 1]

				this.setState((state) => ({
					historyClick: state.historyClick - 1,
					activePage: lastPage,
					pages: {
						...state.pages,
						[lastPage] : lastPanel
					},
					//очистка истории
					historyPanel: {
						...state.historyPanel,
						[activePage]: (state.historyPanel[activePage].length > 1) ? [...state.historyPanel[activePage].slice(0, -1)] : [...state.historyPanel[activePage]]
					},
					historyPage: [...state.historyPage.slice(0, -1)]
				}))
			}
		}

	}

	/*
		переход на одну панель назад в рамках текущей страницы
		удаляет последнюю запись из истории
	*/
	goPanelBack = (event, count = 1) => {
		const { historyPanel, activePage } = this.state
		const historyThisPage = historyPanel[activePage] //история текущей страницы
		const lastPanel = historyThisPage[historyThisPage.length - (count + 1)] //предпоследняя панель по которой был переход
				
		this.setState((state) => ({
			historyClick: state.historyClick - count,
			activePage: activePage,
			pages: {
				...state.pages,
				[activePage] : lastPanel
			},
			//очистка истории
			historyPanel: {
				...state.historyPanel,
				[activePage]: [...state.historyPanel[activePage].slice(0, -count)]
			}
		}))
	}
	
	/*
		добавление новой записи в историю
		переход на новую страницу
	*/
	goPage = (e) => {
		const { page } = e.currentTarget.dataset
		this.__setHistoryPage(page, () => {
			this.setState({ activePage: page });
		})
	}

	/*
		добавление новой записи в историю
		переход на новую панель текущеей страницы
	*/
	goPanel = (panel = "") => {
		this.__setHistoryPanel(panel, () => {
			this.setState((state) => ({
				pages: {
					...state.pages,
					[state.activePage] : panel
				}
			}))
		})
	}

	render() {
		//console.log(this.state)
		const { activePage, pages } = this.state
		const { children, isEpic, id } = this.props
		const { __buildBar } = this
		if (isEpic) { // если включен режим эпик меню
			return (
				<Epic id={id} activeStory={activePage} tabbar={__buildBar(children)}>
					{React.Children.map(children, (Child) =>
						(Child) ?
							React.cloneElement(Child, {
								...Child.props,
								activePanel: pages[activePage],
								navigator: {
									goPage: this.goPage,
									goPanel:this.goPanel,
									goPanelBack: this.goPanelBack,
								},
							})
						: null
					)}
				</Epic>
			)
		} else { // если выключен режим эпик меню
			return (
				<Root activeView={activePage}>
					{React.Children.map(children, (Child) =>
						(Child) ?
							React.cloneElement(Child, {
								...Child.props,
								activePanel: pages[activePage],
								navigator: {
									goPage: this.goPage,
									goPanel:this.goPanel,
									goPanelBack: this.goPanelBack,
								},
							})
						: null
					)}
				</Root>
			)
		}

	}

}

export default Stack;