import React from 'react';
import { Panel, Cell, Avatar, List, Div, Button, Input, FormLayout } from '@vkontakte/vkui';
import Header from '../components/Header/Header';

import app from '../func/app';
import config from '../config';
import { findObjectById } from '../func/utils';
import { showAnswerMessage, showErrorMessage } from '../func/alert';
import { getMissingUsers } from '../func/vk';
import { utilsResolveScreenName } from '../func/generate/methods/utils';
import { setAdmin, delAdmin } from '../func/back';

import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';

class Admin extends React.Component {

	removeAdmin = (e) => {
		e.preventDefault();
		const { id } = e.currentTarget.dataset
		showAnswerMessage("Удалить?", "Вы действительно хотите удалить администратора?", () => {

			//пока асинхронно удаляет пользователя из админов
			delAdmin(+id, () => {})

			app.setState((state) => {
				const index = state.admin_notify.findIndex(item => (item === +id))
				return {
					admin_notify: (index !== -1) ? [...state.admin_notify.slice(0, index), ...state.admin_notify.slice(index + 1)] : [...state.admin_notify]
				}
			})
		})
	}

	addAdmin = () => {
		const { adminInputAdd, apiUsersData, admin_notify } = this.props
		if (adminInputAdd) {
			const screen_name = adminInputAdd
				.replace(/https:\/\//, '')
				.replace(/http:\/\//, '')
				.replace(/m\.vk\.com/, '')
				.replace(/vk\.com/, '')
				.replace(/\//, '')

			utilsResolveScreenName(config.access_token, { screen_name:screen_name }, (response) => {
				const { type } = response
				if (type === 'user') {
					//ок перед нами пользователь
					const { object_id } = response

					if (admin_notify.indexOf(+object_id) === -1 && config.admin_app.indexOf(+object_id) === -1) {

						//пока асинхронно добавляем пользователя в админы
						setAdmin(object_id, () => {})

						const availableUids = apiUsersData.map(({id}) => { return id })
						getMissingUsers(availableUids, config.access_token, [object_id], (ApiUserData) => {
							app.setState((state) => ({
								apiUsersData: (ApiUserData) ? [...ApiUserData, ...state.apiUsersData] : [...state.apiUsersData],
								admin_notify: [+object_id, ...state.admin_notify],
								adminInputAdd: "",
							}))
						})
					} else {
						showErrorMessage("Ошибка!", "Этот пользователь уже есть в списке")
					}
				} else {
					//не ок, перед нами не пользователь
					showErrorMessage("Ошибка!", "Эта ссылка не на пользователя.")
				}
			}, (error) => {
				showErrorMessage("Ошибка!", "Эта ошибка пока нам не известна.")
			})

		} else {
			showErrorMessage("Ошибка!", "Похоже это ссылка не на пользователя.")
		}
	}

	onChangeInput = (e) => {
		const { name, value } = e.currentTarget;
		app.setState({ [name]: value.substr(0, 75) })
	}

	render() {
		const { id, apiUsersData, admin_notify, adminInputAdd } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header name="Настройки"/>
				<FormLayout>
					<div top="Введите ссылку на пользователя">
						<Input value={adminInputAdd} name="adminInputAdd" onChange={this.onChangeInput} type="text"/>
						<Div className="flex_end pr-0 pl-0 pb-0">
							<Button onClick={this.addAdmin}>Добавить</Button>
						</Div>
					</div>
				</FormLayout>
				<List>
					{
						config.admin_app.map((admin) => {
							let userInfo = findObjectById(apiUsersData, +admin)
							return (
								<Cell 
									description={`https://vk.com/id${userInfo.id}`}
									before={<Avatar size={40} src={userInfo.photo_50} />} 
									key={admin}
									href={`https://vk.com/id${admin}`}
									target="_blank"
								>
									{userInfo.first_name}
								</Cell>
							)
						})
					}
					{
						admin_notify.map((admin) => {
							let userInfo = findObjectById(apiUsersData, +admin)
							return (
								<Cell 
									key={admin}
									description={`https://vk.com/id${userInfo.id}`}
									before={<Avatar size={40} src={userInfo.photo_50}/>}
									indicator={<Icon24Cancel data-id={admin} onClick={this.removeAdmin}/>}
									href={`https://vk.com/id${admin}`}
									target="_blank"
								>
									{userInfo.first_name}
								</Cell>
							)
						})
					}
				</List>
			</Panel>
		)
	}
}

export default Admin;