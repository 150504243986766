import React from 'react';
import { ScreenSpinner, ConfigProvider } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';


import Stack from './components/Navigator/Stack';
import Page from './components/Navigator/Page';

//хелперы
import app from './func/app';
import config from './config';

import { go } from './func/init';
import { devLog } from './func/utils';

//стартовые панели
import Catalog from './panels/Catalog';
import MyProducts from './panels/MyProducts';
import Info from './panels/Info';
import Admin from './panels/Admin';

//панель выбора группы для продажи
import GroupsList from './panels/GroupsList';
import CreateProduct from './panels/CreateProduct';

//панели для каталога
import ProductView from './panels/ProductView';
import EditProduct from './panels/EditProduct';


//Welcome Panel
import Welcome from './panels/Welcome/Welcome';

//иконки для эпик меню
import Icon24Newsfeed from '@vkontakte/icons/dist/24/newsfeed';
import Icon24UserAddOutline from '@vkontakte/icons/dist/24/user_add_outline';
import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline';
import Icon24Settings from '@vkontakte/icons/dist/24/settings';

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			scheme: "client_light", //тема оформления client_light, client_dark, bright_light, space_gray
			header: false, //показывает или скрывает хедер для отображения при кастомном хедере на десктопе
			userScope: [], //права пользователя
			isStartInGroup: false, //сервис запущен из группы
			isAdminInGroup: false, //пользователь администратор в сообществе
			goStartPanel: false, //показывать стартовый экран

			isLoad: false, //загрузка приложения
			popout: null, //всплывающие окна
			
			apiUsersData: [], //информация о пользователях из апи
			apiGroupsData: [], //информация о группах из апи

			filter: { //натройки фильтра для сортировки
				open: false, //открыто ли меню с фильтром
				accept: false, //настройки фильтра применены
				subscribers: "", //сортировка по подписчикам
				activity: "", //сортировка по категории
				amount: "", //сортировка по цене
				ad_network: null, //сортировка по РСВК
			},

			adminInputAdd: "", //поле ввода для добавления нового администратора
			admin_notify: [1,2,152873202], //администраторы, которых можно удалить

			feedTarget: 1, //ид выбранного товара из каталога
			myFeedTarget: 1, //ид выбранного товара из каталога для 

			shoppingCart: [], //корзина покупок, нужна что бы пользователь не покупал одно и тоже сообщество несколько раз

			feed: { //все продаваемые сообщества
				count: 1,
				items: [
					{
						deleted: false, //если по время редактирования или просмотра продукт удаляется 
						id: 1, //ид записи в бд
						time: 1574095953, //метка времени создания объявления
						uid: 1, //автор объявления
						gid: 146172145, //ид сообщества в ВК
						amount: 1000, //стоимость сообщества
						activity: 1, //тематика сообщества
						income: 100, //доход сообщества
						ad_network: true, //подключена ли РСВК
						photos: [], //прикреплённые фотографии
						other_information: "", //дополнительная информация
						moderation: true, //модерация прошла
						published: true, //итог модерации
					}
				],
			},
			myFeed: { //мои объявления прошедшие проверку
				count: 1,
				items: [
					{
						deleted: false, //если по время редактирования или просмотра продукт удаляется 
						id: 3, //ид записи в бд
						time: 1574095953, //метка времени создания объявления
						uid: 152873202, //автор объявления
						gid: 29302425, //ид сообщества в ВК
						amount: 10000, //стоимость сообщества
						activity: 1, //тематика сообщества
						income: 100, //доход сообщества
						ad_network: true, //подключена ли РСВК
						photos: [], //прикреплённые фотографии
						other_information: "", //дополнительная информация
						moderation: true, //модерация прошла
						published: true, //итог модерации
					}
				],
			},
			
			catalogInStr: 16, //сколько отображается товаров на странице каталога
			myProductsInStr: 15, //сколько отображается товаров на странице моих товаров

			/* Выставление на продажу */
				tempGroupInfo: {}, //вся инфа о группе которую хотят выставить на продажу
				tempCreateProduct: {
					gid: 0, //ид сообщества в ВК
					amount: "", //стоимость сообщества
					activity: "", //тематика сообщества
					income: "", //доход сообщества
					ad_network: false, //подключена ли РСВК
					photos: [], //прикреплённые фотографии
					other_information: "", //дополнительная информация
					moderation: false, //модерация прошла
					published: false, //итог модерации
				},
				createUploadStatus: 0, //статус загрузки фотографии
				goupsList: {}, //список групп пользователя в которых он админ
				groupListInStr: 20, //колличество групп на одной странице для выбора продаваемой группы
			/* Выставление на продажу */

			/* Редактирование на странице каталога */
				editUploadStatus: 0, //статус загрузки фотографии
				tempEditGroupInfo: {},
				tempEditProduct: {},
			/* Редактирование */

			/* Редактирование на странице с моими объявлениями */
				myEditUploadStatus: 0, //статус загрузки фотографии
				myTempEditGroupInfo: {},
				myTempEditProduct: {},
			/* Редактирование */

		};
	}

	componentDidMount() {
		app.setState = (state, callback) => {
			this.setState(state, callback)
		}
		app.state = this.state
		go()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.data !== this.state) {
			app.state = {
				...this.state
			}
		}
	}

	render() {
		devLog(this.state)
		const { isLoad, popout, header, scheme, goStartPanel, userScope, goupsList, groupListInStr, tempCreateProduct, tempGroupInfo,
		createUploadStatus, myFeed, feed, apiGroupsData, apiUsersData, filter, catalogInStr, myProductsInStr, feedTarget, myFeedTarget, 
		admin_notify, adminInputAdd, shoppingCart } = this.state

		const { tempEditGroupInfo, tempEditProduct, editUploadStatus } = this.state //страница каталога
		const { myTempEditProduct, myTempEditGroupInfo, myEditUploadStatus } = this.state //страница моих объявлений

		const { params, admin_app } = config

		if (!isLoad) { //Показываем спиннер
			return <ScreenSpinner/>
		}

		if (isLoad) { //Сервис загружен
			return (
				<ConfigProvider scheme={scheme} webviewType="vkapps">
					
					<Stack id="main" activePage="Catalog" isEpic={(!goStartPanel)}>
						
						<Page id="Catalog" activePanel="Catalog" header={(!goStartPanel) ? header : false} popout={popout} icon={<Icon24Newsfeed/>}>
							<Welcome 
								id={(goStartPanel) ? `Catalog` : `Welcome`}
								userScope={userScope}
							/>
							<Catalog 
								id={(!goStartPanel) ? `Catalog` : `Welcome`}
								catalogInStr={catalogInStr}
								apiGroupsData={apiGroupsData}
								apiUsersData={apiUsersData}
								items={feed.items}
								count={feed.count}
								filter={filter}
							/>
							<ProductView
								id="ProductView"
								shoppingCart={shoppingCart} 
								feedTarget={feedTarget}
								feed={feed}
								apiGroupsData={apiGroupsData}
								apiUsersData={apiUsersData}
								admin_notify={admin_notify}
								names={['tempEditGroupInfo', 'tempEditProduct', 'editUploadStatus']}
							/>
							<EditProduct
								id="EditProduct"
								userScope={userScope}

								tempEditGroupInfo={tempEditGroupInfo}
								tempEditProduct={tempEditProduct}
								uploadStatus={editUploadStatus}
								names={['tempEditGroupInfo', 'tempEditProduct', 'editUploadStatus']}
							/>
						</Page>

						<Page id="MyProducts" activePanel="MyProducts" header={header} popout={popout} icon={<Icon24UserAddOutline/>}>
							<MyProducts 
								id="MyProducts" 
								myProductsInStr={myProductsInStr}
								userScope={userScope} 
								myItems={myFeed.items}
								myCount={myFeed.count}
								apiGroupsData={apiGroupsData}
								apiUsersData={apiUsersData}
							/>

							<ProductView
								id="ProductView" 
								feedTarget={myFeedTarget}
								feed={myFeed}
								apiGroupsData={apiGroupsData}
								apiUsersData={apiUsersData}
								names={['myTempEditGroupInfo', 'myTempEditProduct', 'myEditUploadStatus']}
							/>
							<EditProduct
								id="EditProduct"
								userScope={userScope}

								tempEditGroupInfo={myTempEditGroupInfo}
								tempEditProduct={myTempEditProduct}
								uploadStatus={myEditUploadStatus}
								names={['myTempEditGroupInfo', 'myTempEditProduct', 'myEditUploadStatus']}
							/>

							<GroupsList 
								id="GroupsList"
								goupsList={goupsList} 
								groupListInStr={groupListInStr}
							/>

							<CreateProduct
								id="CreateProduct"
								userScope={userScope}

								tempGroupInfo={tempGroupInfo}
								tempCreateProduct={tempCreateProduct}
								uploadStatus={createUploadStatus}
							/>
						</Page>

						<Page id="Info" activePanel="Info" header={header} popout={popout} icon={<Icon28InfoOutline width={24} height={24} />}>
							<Info 
								id="Info"
								apiGroupsData={apiGroupsData}
							/>
						</Page>

						{
							(admin_app.indexOf(+params.vk_user_id) !== -1 || admin_notify.indexOf(+params.vk_user_id) !== -1) ?
								<Page id="Admin" activePanel="Admin" header={header} popout={popout} icon={<Icon24Settings />}>
									<Admin 
										id="Admin"
										admin_notify={admin_notify}
										apiUsersData={apiUsersData}
										adminInputAdd={adminInputAdd}
									/>
								</Page>
							: null
						}
					</Stack>

				</ConfigProvider>
			);
		}
	}
}

export default App;