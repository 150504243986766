/* 
	рендер списка групп
*/
import React from 'react';
import { List } from '@vkontakte/vkui';
import CellBlock from '../../components/CellBlock/CellBlock';
import Number from '../../components/Number/Number';

import { findObjectById } from '../../func/utils';
import config from '../../config';

import './style.css';

class GroupsBlock extends React.Component {
	render() {
		let { items, onTarget, inStr, apiGroupsData, description } = this.props
		return (
			<List>
				{
					items.map((item, i) => {
						let oldItem, activity
						
						if (description === "activity") {
							activity = findObjectById(config.activity, item.activity).name
						}

						if (apiGroupsData) {
							let { gid } = item
							oldItem = {...item}
							item = findObjectById(apiGroupsData, gid)
						}

						return (i + 1 <= inStr) ?
							<CellBlock
								key={item.id}
								title={item.name}
								description={
									(description === "activity") ? 
										activity
									: 
										(item.members_count > 0) ? 
											<Number type="separation" number={item.members_count} text={['подписчик', 'подписчика', 'подписчиков']}/>
										: `Нет подписчиков`
								}
								verified={item.verified}
								onClick={() => { onTarget(item, oldItem) }}
								photo_50={item.photo_50}
							/>
						: null
					})
				}
			</List>
		);
	}
}

export default GroupsBlock