import React from 'react';
import { Panel } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import OneProduct from '../components/OneProduct/OneProduct';

import app from '../func/app';
import config from '../config';
import { getAddScope, getAllowMessagesFromGroup } from '../func/vk';
import { showInfoMessage } from '../func/alert';
import { upload } from '../func/uploadPhoto'
import { setProduct } from '../func/back'

class CreateProduct extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onChangeInput = (e) => {
		const { name, value } = e.currentTarget;
		app.setState((state) => ({
			tempCreateProduct: {
				...state.tempCreateProduct,
				[name]: value.substr(0, 750)
			}
		}))
	}

	onChangeSwitch = (e) => {
		const { name, checked } = e.currentTarget;
		app.setState((state) => ({
			tempCreateProduct: {
				...state.tempCreateProduct,
				[name]: checked
			}
		}))
	}

	onChangeSelect = (e) => {
		const { name, selectedIndex, options } = e.currentTarget;
		app.setState((state) => ({
			tempCreateProduct: {
				...state.tempCreateProduct,
				[name]: +options[selectedIndex].value 
			}
		}))
	}

	onPublishProduct = (e) => {
		const { tempCreateProduct, tempGroupInfo } = this.props
		const { gid, amount, activity, income, ad_network, photos, other_information } = tempCreateProduct
		const { members_count } = tempGroupInfo
		const { info_group_id } = config

		getAllowMessagesFromGroup(info_group_id, (allow) => {
			this.setProduct(gid, amount, activity, income, ad_network, photos, other_information, members_count)
		}, (disallow) => {
			this.setProduct(gid, amount, activity, income, ad_network, photos, other_information, members_count)
		})
	}

	setProduct = (gid, amount, activity, income, ad_network, photos, other_information, members_count) => {
		const { navigator } = this.props

		setProduct(gid, amount, activity, income, ad_network, photos, other_information, members_count, (response) => {
			console.log(response)
			showInfoMessage('Объявление отправлено на модерацию','', () => { navigator.goPanelBack({}, 2) })
		})
		
	}

	onChangePhotoButton = (e) => {
		const savedEvent = {...e};
		const { userScope } = this.props
		if (userScope.indexOf("photos") !== -1) {
			console.log('ок, права есть')
			this._onChangePhoto(e)
		} else {
			getAddScope(["photos"], (response) => {
				const { access_token, scope } = response
				if (scope.split(',').indexOf("photos") !== -1) {
					config.set({
						access_token: access_token
					},() => {
						app.setState({
							userScope: scope.split(',')
						}, ()=> {
							//ок, права есть
							console.log('ок, права есть')
							this._onChangePhoto(savedEvent)
						})
					})
				}
			})
		}
	}

	onDeletePhoto = (e) => {
		const { index } = e.currentTarget.dataset
		app.setState((state, props) =>({
			tempCreateProduct: {
				...state.tempCreateProduct,
				photos: [...state.tempCreateProduct.photos.slice(0, +index), ...state.tempCreateProduct.photos.slice(+index + 1)],
			}
		}))
	}

	_onChangePhoto = (e) => {
		const files = [...e.target.files]
		if (files.length > 0) {
			upload("createUploadStatus", files[0], (response) => {
				app.setState((state) => ({
					createUploadStatus: 0,
					tempCreateProduct: {
						...state.tempCreateProduct,
						photos: [...state.tempCreateProduct.photos, ...response],
					}
				}))
			})
		}
	}

	render() {
		const { id, navigator, tempGroupInfo, tempCreateProduct, uploadStatus } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigator.goPanelBack } name="Создание объявления"/>

				<OneProduct
					item={tempCreateProduct}
					groupInfo={tempGroupInfo}
					
					uploadStatus={uploadStatus}

					onDeletePhoto={this.onDeletePhoto}
					onChangeInput={this.onChangeInput}
					onChangeSwitch={this.onChangeSwitch}
					onChangeSelect={this.onChangeSelect}
					onPublishProduct={this.onPublishProduct}
					onChangePhotoButton={this.onChangePhotoButton}
				/>
			</Panel>
		)
	}
}

export default CreateProduct;