import React from 'react';
import { FormLayout, Input, Select, Switch, Div, Button, File, Textarea, InfoRow, Progress } from '@vkontakte/vkui';
import PhotoGalery from '../PhotoGalery/PhotoGalery';
import { showErrorMessage } from '../../func/alert';
import './style.css';

import config from '../../config';

import Icon24Gallery from '@vkontakte/icons/dist/24/gallery';

class OneProduct extends React.Component {

	formatCount = (count) => {
		return String(count).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
	}

	onPublishProduct = () => {
		const { onPublishProduct, item } = this.props
		const { amount, activity } = item

		if (!amount || !activity) {
			showErrorMessage('Ошибка!', 'Не все поля со звёздочками заполнены.')
		} else {
			onPublishProduct()
		}
	}

	render() {
		let { item, groupInfo, onDeletePhoto, onChangeInput, onChangeSwitch, onChangeSelect, onChangePhotoButton, uploadStatus, publishButtonName } = this.props
		return (
				<div>
					<FormLayout className="__FormLayoutBlock">
						<Input
							value={groupInfo.name}
							type="text"
							top="Название сообщества"
							disabled={true}
						/>

						<Input
							value={this.formatCount(groupInfo.members_count)}
							type="text"
							top="Количество подписчиков"
							disabled={true}
						/>

						<Input
							value={`https://vk.com/stats?gid=${groupInfo.id}`}
							type="text"
							top="Ссылка на статистику"
							disabled={true}
						/>

						<Input
							type="number"
							top={<span>Введите стоимость <span style={{color: "red"}}>*</span></span>}
							name="amount"
							min="0"
							onChange={onChangeInput}
							value={item.amount}
						/>

						<Select name="activity" defaultValue={item.activity} onChange={onChangeSelect} top={<span>Выберите тематику <span style={{color: "red"}}>*</span></span>} placeholder=" ">
							{
								config.activity.map((item) => {
									return <option key={item.id} value={item.id}>{item.name}</option>
								})
							}
						</Select>

						<Input
							type="number"
							top="Укажите доход сообщества в месяц"
							min="0"
							name="income"
							onChange={onChangeInput}
							value={item.income}
						/>
						
						<Div className="pb-0 flex_start">
							<Switch checked={item.ad_network} name="ad_network" onChange={onChangeSwitch}/> 
							<div className="pl-10">РСВК подключена</div>
						</Div>

					</FormLayout>

					{
						(uploadStatus) ?
							<Div className="pb-0">
								<InfoRow title="Загрузка...">
									<Progress value={uploadStatus} />
								</InfoRow>
							</Div>
						: null
					}
					{
						(item.ad_network) ?
							<div>
								{
									(item.photos.length < 3) ?
										<Div className="pb-0 flex_end">
											<File onChange={onChangePhotoButton} level="secondary" accept="image/*" >
												<div className="flex_center">
													<Icon24Gallery width="16" height="16" className="pr-5"/>
													Прикрепить фото
												</div>
											</File>
										</Div>
									: null
								}
								{
									(item.photos.length > 0) ?
										<Div>
											<PhotoGalery onDelete={onDeletePhoto} photos={item.photos}/>
										</Div>
									: null
								}
							</div>
						: null
					}
					<FormLayout className="__FormLayoutBlock">
						<Textarea 
							top={`Дополнительная информация ${item.other_information.length}/750`} 
							placeholder=""
							name="other_information" 
							onChange={onChangeInput} 
							value={item.other_information}
						/>
					</FormLayout>

					<Div className="flex_end">
						<Button onClick={this.onPublishProduct}>{(publishButtonName) ? publishButtonName : `Опубликовать`}</Button>
					</Div>
				</div>
		);
	}
}

export default OneProduct;