//Этот файл сгенерирован автоматически
import { apiRequest } from '../../vkApiRequest';


//Confirms a tag on a photo.
//user
export function photosConfirmTag(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.confirmTag",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allows to copy a photo to the "Saved photos" album
//user
export function photosCopy(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.copy",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Creates an empty photo album.
//user
export function photosCreateAlbum(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.createAlbum",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Adds a new comment on the photo.
//user
export function photosCreateComment(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.createComment",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Deletes a photo.
//user
export function photosDelete(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.delete",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Deletes a photo album belonging to the current user.
//user
export function photosDeleteAlbum(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.deleteAlbum",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Deletes a comment on the photo.
//user
export function photosDeleteComment(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.deleteComment",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Edits the caption of a photo.
//user
export function photosEdit(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.edit",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Edits information about a photo album.
//user
export function photosEditAlbum(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.editAlbum",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Edits a comment on a photo.
//user
export function photosEditComment(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.editComment",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of a user's or community's photos.
//user,service
export function photosGet(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.get",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of a user's or community's photo albums.
//user,service
export function photosGetAlbums(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getAlbums",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns the number of photo albums belonging to a user or community.
//user
export function photosGetAlbumsCount(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getAlbumsCount",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of photos belonging to a user or community, in reverse chronological order.
//user
export function photosGetAll(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getAll",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of comments on a specific photo album or all albums of the user sorted in reverse chronological order.
//user
export function photosGetAllComments(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getAllComments",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns information about photos by their IDs.
//user,service
export function photosGetById(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getById",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns an upload link for chat cover pictures.
//user
export function photosGetChatUploadServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getChatUploadServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of comments on a photo.
//user
export function photosGetComments(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getComments",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns the server address for market album photo upload.
//user
export function photosGetMarketAlbumUploadServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getMarketAlbumUploadServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns the server address for market photo upload.
//user
export function photosGetMarketUploadServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getMarketUploadServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns the server address for photo upload in a private message for a user.
//user,group
export function photosGetMessagesUploadServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getMessagesUploadServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of photos with tags that have not been viewed.
//user
export function photosGetNewTags(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getNewTags",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns the server address for owner cover upload.
//user,group
export function photosGetOwnerCoverPhotoUploadServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getOwnerCoverPhotoUploadServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns an upload server address for a profile or community photo.
//user
export function photosGetOwnerPhotoUploadServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getOwnerPhotoUploadServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of tags on a photo.
//user
export function photosGetTags(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getTags",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns the server address for photo upload.
//user
export function photosGetUploadServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getUploadServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of photos in which a user is tagged.
//user
export function photosGetUserPhotos(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getUserPhotos",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns the server address for photo upload onto a user's wall.
//user
export function photosGetWallUploadServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.getWallUploadServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Makes a photo into an album cover.
//user
export function photosMakeCover(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.makeCover",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Moves a photo from one album to another.
//user
export function photosMove(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.move",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Adds a tag on the photo.
//user
export function photosPutTag(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.putTag",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Removes a tag from a photo.
//user
export function photosRemoveTag(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.removeTag",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Reorders the album in the list of user albums.
//user
export function photosReorderAlbums(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.reorderAlbums",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Reorders the photo in the list of photos of the user album.
//user
export function photosReorderPhotos(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.reorderPhotos",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Reports (submits a complaint about) a photo.
//user
export function photosReport(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.report",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Reports (submits a complaint about) a comment on a photo.
//user
export function photosReportComment(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.reportComment",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Restores a deleted photo.
//user
export function photosRestore(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.restore",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Restores a deleted comment on a photo.
//user
export function photosRestoreComment(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.restoreComment",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Saves photos after successful uploading.
//user
export function photosSave(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.save",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Saves market album photos after successful uploading.
//user
export function photosSaveMarketAlbumPhoto(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.saveMarketAlbumPhoto",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Saves market photos after successful uploading.
//user
export function photosSaveMarketPhoto(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.saveMarketPhoto",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Saves a photo after being successfully uploaded. URL obtained with [vk.com/dev/photos.getMessagesUploadServer|photos.getMessagesUploadServer] method.
//user,group
export function photosSaveMessagesPhoto(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.saveMessagesPhoto",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Saves cover photo after successful uploading.
//user,group
export function photosSaveOwnerCoverPhoto(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.saveOwnerCoverPhoto",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Saves a profile or community photo. Upload URL can be got with the [vk.com/dev/photos.getOwnerPhotoUploadServer|photos.getOwnerPhotoUploadServer] method.
//user
export function photosSaveOwnerPhoto(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.saveOwnerPhoto",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Saves a photo to a user's or community's wall after being uploaded.
//user
export function photosSaveWallPhoto(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.saveWallPhoto",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of photos.
//user,service
export function photosSearch(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("photos.search",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}
