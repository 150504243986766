import React from 'react';
//import { Button } from '@vkontakte/vkui';

import './style.css';

class CardOutlineContainer extends React.Component {
	render() {
		let { className, children } = this.props
		return (
			<div className={`${className} __cardOutlineContainer`}>
				{children}
			</div>
		);
	}
}

export default CardOutlineContainer;