import React from 'react';
import { Panel, Spinner } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import GroupsBlock from '../components/GroupsBlock/GroupsBlock';

import { groupsGet } from '../func/generate/methods/groups'
import { showErrorMessage } from '../func/alert';

import app from '../func/app';
import config from '../config';

class GroupsList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoad: false,
		};
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScrollThisPage, false);
	}

	componentDidMount() {
		const { loadGroupsList, access_token, params, filterGroupsList, groupFields } = config
		const { vk_user_id } = params
		if (loadGroupsList) {
			//получаем данные
			groupsGet(access_token, {
				user_id: +vk_user_id,
				extended: 1,
				filter: filterGroupsList.join(','),
				fields: groupFields.join(','),
				offset: 0,
				count: 1000
			}, (groupsList) => {
				app.setState({
					goupsList: groupsList
				}, () => {
					config.set({ loadGroupsList: false }, () => { this.setState({ isLoad: true }) })
				})
			}, (error) => {
				console.log(error)
			})
		} else {
			//данные уже есть просто рендерим их 
			this.setState({	isLoad: true })
		}
		window.addEventListener('scroll', this.onScrollThisPage, false);
	}

	onScrollThisPage = () => {
		const { groupListInStr, goupsList } = this.props
		const delay = document.body.scrollHeight / 10;
		if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - delay) {
			if ( goupsList.items.length > groupListInStr ) {
				app.setState((state) => ({
					groupListInStr: state.groupListInStr + 20,
				}))
			}
		}
	}

	onTarget = (item) => {
		const { navigator } = this.props
		const { id, members_count } = item
		//проверяем, чтобы сообщество было больше 1000 подписчиков
		if (+members_count >= 1000) {
			app.setState((state) => ({
				tempGroupInfo: item,
				tempCreateProduct: {
					...state.tempCreateProduct,
					gid: id, //ид сообщества в ВК
					amount: "", //стоимость сообщества
					activity: "", //тематика сообщества
					income: "", //доход сообщества
					ad_network: false, //подключена ли РСВК
					photos: [], //прикреплённые фотографии
					other_information: "", //дополнительная информация
					moderation: false, //модерация прошла
					published: false, //итог модерации
				}
			}), () => {
				navigator.goPanel('CreateProduct')
			})	
		} else {
			showErrorMessage('Ошибка!', 'Сообщество должно иметь больше 1000 подписчиков.')
		}
	}

	render() {
		const { isLoad } = this.state
		const { id, navigator, goupsList, groupListInStr } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigator.goPanelBack } name="Список сообществ"/>
				{
					(isLoad) ?
						<GroupsBlock 
							inStr={groupListInStr} 
							onTarget={this.onTarget} 
							items={goupsList.items}
						/>
					: 
						<Spinner size="regular" style={{ marginTop: 20 }} />
				}
			</Panel>
		)
	}
}

export default GroupsList;