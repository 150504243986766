import React from 'react';
import { Cell, Avatar, Separator } from '@vkontakte/vkui';
import './style.css';

import config from '../../config';
import { findObjectById } from '../../func/utils';

class GroupHeader extends React.Component {

	getCoverImage = (cover) => {
		if (cover.enabled) {
			return cover.images[2].url
		} else {
			return false
		}
	}

	render() {
		let { groupInfo, actionBlock, description, target } = this.props
		let { cover } = groupInfo
		let coverImage = this.getCoverImage(cover)
		let activity
		if (target) {
			activity = findObjectById(config.activity, target.activity).name
		} else {
			activity = groupInfo.activity
		}
		//target

		return (
			<div>
				{
					(coverImage) ?
						<img alt="" className="__GroupHeaderImage" src={coverImage}/>
					: null
				}
				<Cell 
					href={`https://vk.com/club${groupInfo.id}`}
					target="_blank"
					indicator={<Avatar src={groupInfo.photo_50}/>}
					multiline={true}
					description={(description) ? description : activity}
				>
					{groupInfo.name}
				</Cell>
				{actionBlock}
				<Separator/>
			</div>
		);
	}
}

export default GroupHeader;