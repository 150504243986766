let config = {
	app_id: null, //ид приложения
	admin_app: [33873307,152873202,2163500,1746569], //админы, 
	group_id: null, //группа для принятия платежей
	info_group_id: 186900082,  //группа для отображения информации 186900082
	params: null, //параметры запуска
	hash: null, //Хеш приложения при запуске
	userData: null, //Данные о пользователе vk api
	wsUrl: (process.env.NODE_ENV === 'development') ? 'wss://echo.websocket.org' : 'wss://echo.websocket.org', //url wss сервера
	backUrl: (process.env.NODE_ENV === 'development') ? 'https://communitystore.kupiapp2.ru/api/index.php' : 'https://communitystore.kupiapp2.ru/api/index.php', //url бекенда
	uploadUrl:  (process.env.NODE_ENV === 'development') ? 'https://communitystore.kupiapp2.ru/api/upload.php' : 'https://communitystore.kupiapp2.ru/api/upload.php', //url бекенда
	api_version: '5.108', //версия апи
	access_token: '27f7b7d627f7b7d627f7b7d6d5279a58ca227f727f7b7d67a3efc152a405ef3189795bb', //ключ доступа апи

	desktopHeader: true, //кастомный хедер для десктопа
	groupChange: true, //другой сервис в группе. true если нужно загружать другие панели для сервиса запущенного в группе
	startPanel: true, //показывать стартовую панель. Если true будет 1 раз загружать стартовую панель, если сервис загружен не в группе.
	userFields: ['photo_50', 'online', 'verified', 'sex'], //данные которые нужно запрашивать у пользователей
	groupFields: ['photo_50', 'photo_100', 'verified', 'members_count', 'cover', 'activity'], //данные которые нужно запрашивать у групп
	mobileConsole: false, //подключить в режиме разработки мобильную консоль для отладки iOS
	mibileConsoleCDN: 'cdn.jsdelivr.net/npm/eruda', //CDN скрипта с мобиьной консолью
	localStorageСounter: false, //включает счётчик входов в приложение на основе localStorage

	filterGroupsList: ['admin'], //фильтр для получения групп в списке продаваемых групп

	loadGroupsList: true, //данные по группам уже получены если false

	activity: [ //все доступные тематики
		{ id: 1, name: "Авто мото" },
		{ id: 2, name: "Активный отдых" },
		{ id: 3, name: "Бизнес" },
		{ id: 4, name: "Дизайн и графика" },
		{ id: 5, name: "Дом и семья" },
		{ id: 6, name: "Здоровье" },
		{ id: 7, name: "Знакомства" },
		{ id: 8, name: "Игры" },
		{ id: 9, name: "ИТ компьютеры и софт" },
		{ id: 10, name: "Кино" },
		{ id: 11, name: "Красота и мода" },
		{ id: 12, name: "Кулинария" },
		{ id: 13, name: "Культура и искусство" },
		{ id: 14, name: "Литература" },
		{ id: 15, name: "Музыка" },
		{ id: 16, name: "Наука и техника" },
		{ id: 17, name: "Недвижимость" },
		{ id: 18, name: "Образование" },
		{ id: 19, name: "Обустройство и ремонт" },
		{ id: 20, name: "Путешествия" },
		{ id: 21, name: "Развлечения" },
		{ id: 22, name: "Спорт" },
		{ id: 23, name: "Фото" },
		{ id: 24, name: "Эзотерика" },
		{ id: 25, name: "Эротика" },
		{ id: 26, name: "Юмор" },
		{ id: 27, name: "Другое" },
	],
	member_count: [ //все доступные варианты фильтра по подписчикам
		{ id: 1, name: "до 10 000 чел.", min: 1000, max: 10000 },
		{ id: 2, name: "от 10 000 до 100 000", min: 10000, max: 100000} ,
		{ id: 3, name: "от 100 000 до 500 000", min: 100000, max: 500000 },
		{ id: 4, name: "от 500 000 до 1 000 000", min: 500000, max: 1000000 },
		{ id: 5, name: "от 1 000 000 и выше", min: 1000000, max: 99999999 },
	],
	amount: [ //все доступные варианты фильтра по цене
		{ id: 1, name: "до 5 000 руб.", min: 1, max: 5000 },
		{ id: 2, name: "от 5 000 до 20 000", min: 5000, max: 20000 },
		{ id: 3, name: "от 20 000 до 100 000", min: 20000, max: 100000 },
		{ id: 4, name: "от 100 000 до 500 000", min: 100000, max: 500000 },
		{ id: 5, name: "от 500 000 до 1 000 000", min: 500000, max: 1000000 },
		{ id: 6, name: "от 1 000 000 и выше", min: 1000000, max: 99999999 },
	],

	set: function(data, callback = () => {}) { //смена конфига
		for (let key in data) {
			this[key] = data[key]
		}
		callback(true)
	}
}

export default config;