import config from '../config';
import { setLocalStorage, getLocalStorage } from './utils';

export function startLocalStorageСounter() {
	if (config.localStorageСounter) {
		const name = 'visit';
		//получаем данные 
		let visit = getLocalStorage(name)
		if (visit) {
			setLocalStorage(name, +visit + 1)
		} else {
			setLocalStorage(name, 1)
		}
	}
}