import React from 'react';
import { Panel, Div, Button } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import OneProduct from '../components/OneProduct/OneProduct';
import InfoPlaceholder from '../components/InfoPlaceholder/InfoPlaceholder';

import app from '../func/app';
import config from '../config';
import { getAddScope } from '../func/vk';
import { findObjectIndex } from '../func/utils';
import { upload } from '../func/uploadPhoto'
import { editProduct } from '../func/back'

import Icon56InfoOutline from '@vkontakte/icons/dist/56/info_outline';

class EditProduct extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onChangeInput = (e) => {
		const { names } = this.props
		const { name, value } = e.currentTarget;
		app.setState((state) => ({
			[names[1]]: {
				...state[names[1]],
				[name]: value.substr(0, 750)
			}
		}))
	}

	onChangeSwitch = (e) => {
		const { names } = this.props
		const { name, checked } = e.currentTarget;
		app.setState((state) => ({
			[names[1]]: {
				...state[names[1]],
				[name]: checked
			}
		}))
	}

	onChangeSelect = (e) => {
		const { names } = this.props
		const { name, selectedIndex, options } = e.currentTarget;
		app.setState((state) => ({
			[names[1]]: {
				...state[names[1]],
				[name]: +options[selectedIndex].value 
			}
		}))
	}

	onPublishProduct = (e) => {
		const { navigator, tempEditProduct, tempEditGroupInfo } = this.props
		const { id, gid, amount, activity, income, ad_network, photos, other_information } = tempEditProduct
		const { members_count } = tempEditGroupInfo

		//пока асинхронно
		editProduct(id, gid, amount, activity, income, ad_network, photos, other_information, members_count, () => {})

		app.setState((state) => {
			const index = findObjectIndex(state.feed.items, id)
			const myIndex = findObjectIndex(state.myFeed.items, id)

			const myTempId = state.myTempEditProduct.id || false
			const tempId = state.tempEditProduct.id || false

			return (
				{
					feed: {
						...state.feed,
						items: (index !== false) ? [...state.feed.items.slice(0, index), {...state.feed.items[index], ...tempEditProduct}, ...state.feed.items.slice(index + 1)] : [...state.feed.items]
					},
					myFeed: {
						...state.myFeed,
						items: (myIndex !== false) ? [...state.myFeed.items.slice(0, myIndex), {...state.myFeed.items[myIndex], ...tempEditProduct}, ...state.myFeed.items.slice(myIndex + 1)] : [...state.myFeed.items]
					},
					//выбранные продукты тоже изменяем если они выбраны
					myTempEditProduct: (myTempId === id) ? { ...state.myTempEditProduct, ...tempEditProduct } : { ...state.myTempEditProduct },
					tempEditProduct: (tempId === id) ? { ...state.tempEditProduct, ...tempEditProduct } : { ...state.tempEditProduct }
				}
			)
		}, navigator.goPanelBack)
	}

	onChangePhotoButton = (e) => {
		const savedEvent = {...e};
		const { userScope } = this.props
		if (userScope.indexOf("photos") !== -1) {
			console.log('ок, права есть')
			this._onChangePhoto(e)
		} else {
			getAddScope(["photos"], (response) => {
				const { access_token, scope } = response
				if (scope.split(',').indexOf("photos") !== -1) {
					config.set({
						access_token: access_token
					},() => {
						app.setState({
							userScope: scope.split(',')
						}, ()=> {
							//ок, права есть
							console.log('ок, права есть')
							this._onChangePhoto(savedEvent)
						})
					})
				}
			})
		}
	}

	onDeletePhoto = (e) => {
		const { names } = this.props
		const { index } = e.currentTarget.dataset
		app.setState((state, props) =>({
			[names[1]]: {
				...state[names[1]],
				photos: [...state[names[1]].photos.slice(0, +index), ...state[names[1]].photos.slice(+index + 1)],
			}
		}))
	}

	_onChangePhoto = (e) => {
		const { names } = this.props
		const files = [...e.target.files]
		
		upload(names[2], files[0], (response) => {
			app.setState((state) => ({
				[names[2]]: 0,
				[names[1]]: {
					...state[names[1]],
					photos: [...state[names[1]].photos, ...response],
				}
			}))
		})
	
	}

	render() {
		const { id, navigator, tempEditGroupInfo, tempEditProduct, uploadStatus } = this.props
		console.log((tempEditProduct.deleted))
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ (tempEditProduct.deleted) ? () => { navigator.goPanelBack({}, 2) } : navigator.goPanelBack  } name="Редактирование"/>
				{
					(tempEditProduct.deleted) ?
						<InfoPlaceholder
							icon={<Icon56InfoOutline/>}
							title="Упс..."
							text="Похоже Вы удалили это объявление"
							action={
								<Div className="flex_center pt-0">
									<Button onClick={() => { navigator.goPanelBack({}, 2) }}> 
										В каталог
									</Button>
								</Div>
							}
						/>
					: 
						<OneProduct
							item={tempEditProduct}
							groupInfo={tempEditGroupInfo}
							
							uploadStatus={uploadStatus}

							onDeletePhoto={this.onDeletePhoto}
							onChangeInput={this.onChangeInput}
							onChangeSwitch={this.onChangeSwitch}
							onChangeSelect={this.onChangeSelect}
							onPublishProduct={this.onPublishProduct}
							onChangePhotoButton={this.onChangePhotoButton}

							publishButtonName="Сохранить"
						/>
				}

			</Panel>
		)
	}
}

export default EditProduct;