/* 
	рендер кнопки ещё
*/
import React from 'react';
import { Div, Button, Spinner } from '@vkontakte/vkui';

import './style.css';

class Pagination extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetching: false,
		}
	}

	showNextPage = () => {
		let { showNextPage } = this.props
		this.setState({
			isFetching: true,
		}, () => {
			showNextPage(() => {
				this.setState({
					isFetching: false,
				})
			})
		})
	}

	render() {
		let { isFetching } = this.state
		let { count, navCount, navOffset } = this.props
		return (
			<div>
				{
					(count > navCount+navOffset) ?
						(isFetching) ? 
							<Spinner size="regular" style={{ marginTop: 25, marginBottom: 25 }} />
						:
						<Div className="flex_center">
							<Button onClick={this.showNextPage} level="secondary" style={{marginBottom: "5px"}}>Ещё</Button>
						</Div>
					: null
				}
			</div>
		);
	}
}

export default Pagination