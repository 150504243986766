import moment from "moment";

export function devErrorLog(e) {
	if (process.env.NODE_ENV === 'development') {
		console.error(e)
	}
}

export function devLog(any) {
	if (process.env.NODE_ENV === 'development') {
		console.log(any)
	}
}

export function throwDevError(error) {
	if (process.env.NODE_ENV === 'development') {
		throw error
	}
}

export function isRetina() {
	return window.devicePixelRatio > 1
}

//получает параметры запуска
export function getParams() {
		let params = window
		.location
		.search
		.replace('?','')
		.split('&')
		.reduce(
			function(p,e){
				var a = e.split('=');
				p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
				return p;
			},
			{}
		);

		return params;
}

//получает hash
export function getHash() {
		let itog;
		let params = window
		.location
		.href
		.replace('?','')
		.split('#')

		if (params[1]) {
			itog = params[1]
				.replace('#','')
				.split('&')
				.reduce(
					function(p,e){
						var a = e.split('=');
						p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
						return p;
					},
					{}
				);
		}
		
		if (itog) {
			return itog;
		} else {
			return false;
		}
}

export function desktopShare(url, image, title, comment = '') {
	let params = {
		title: title,
		image: image,
		noparse: "1",
		comment: comment
	};

	let popupName = '_blank',
		width = 650,
		height = 610,
		left = Math.max(0, (window.screen.width - width) / 2),
		top = Math.max(0, (window.screen.height - height) / 2),
		surl = 'https://vk.com/share.php?url=' + encodeURIComponent(url),
		popupParams = 'width='+width+',height='+height+',left='+left+',top='+top+',menubar=0,toolbar=0,location=0,status=0',
		popup = false;

	try {
		let doc_dom = '', loc_hos = '';
		try {
			doc_dom = document.domain;
			loc_hos = window.location.host;
		} catch (e) {
		}
		if (doc_dom !== loc_hos) {
			let ua = window.navigator.userAgent.toLowerCase();
			if (!/opera/i.test(ua) && /msie/i.test(ua)) {
				this._inlineShare(popup, surl, params)
			}
		}
		popup = window.open('', popupName, popupParams);
		let text = '<form accept-charset="UTF-8" action="' + surl + '" method="POST" id="share_form">';
		for (let i in params) {
			text += '<input type="hidden" name="' + i + '" value="' + params[i].toString().replace(/"/g, '&myquot;').replace(/&quot/ig, '&myquot') + '" />';
		}
		text += '</form>';
		text += '<script type="text/javascript">document.getElementById("share_form").submit()</script>';

		text = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">' +
			'<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">' +
			'<head><meta http-equiv="content-type" content="text/html; charset=UTF-8" /></head>' +
			'<body>' + text + '</body></html>';
		popup.document.write(text);
		popup.focus();
	} catch (e) { // ie with changed domain.
		_inlineShare(popup, surl, params, popupName, popupParams)
	}
}

function _inlineShare(popup, surl, params, popupName, popupParams) {
	try {
		if (popup) {
			popup.close();
		}
		surl += '?';
		for (let i in params) {
			surl += encodeURIComponent(i) + '=' + encodeURIComponent(params[i]) + '&';
		}
		popup = window.open(surl, popupName, popupParams);
		popup.focus();
	} catch (e) {
		alert("Sharing error: " + e.message)
	}
}

export function getAndroidVersion() {
	let ua = (window.navigator.userAgent).toLowerCase();
	// eslint-disable-next-line
	let match = ua.match(/android\s([0-9\.]*)/);
	if (ua.indexOf('chrome/6') !== -1) {
		return false
	}
	return match ? parseInt(match[1], 10) : false
}

export function getIosVersion() {
	if (/iP(hone|od|ad)/.test(navigator.platform)) {
		let v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
		return parseInt(v[1], 10)
	} else {
		return false
	}
}

export function isDeviceSupported() {
	return !(getAndroidVersion() && getAndroidVersion() <= 4) || (getIosVersion() && getIosVersion() <= 8)
}

//сортирует объект по ключу
export function dynamicSort(property) {
	let sortOrder = 1;
	if(property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a,b) {
		let result = (a[property] > b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;

		return result * sortOrder;
	}
}

//сортирует объект по ключу
export function memberSort(property, apiGroupsData) {

	let sortOrder = 1;
	if(property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}

	return (a,b) => {
		const a_info = findObjectById(apiGroupsData, +a.gid)
		const b_info = findObjectById(apiGroupsData, +b.gid)

		let result = (a_info[property] > b_info[property]) ? -1 : (a_info[property] > b_info[property]) ? 1 : 0;

		return result * sortOrder;
	}
}

//разбивает массив на чанки
export function chunk(arr, chunkSize) {
	let R = [];
	for (let i=0; i<arr.length; i+=chunkSize)
			R.push(arr.slice(i,i+chunkSize));
		return R;
}


export function forEachPromise(items, fn) {
	return items.reduce(function (promise, item) {
		return promise.then(function () {
			return fn(item);
		});
	}, Promise.resolve());
}

//убирает повторы из массива возвращает новый без повторов
export function unique(arr) {
  let obj = {};

  for (let i = 0; i < arr.length; i++) {
	let str = arr[i];
	obj[str] = true;
  }
  return Object.keys(obj);
}

//Склонение по числительному titles = ['подписчик', 'подписчика', 'подписчиков']
export function declOfNum(number, titles) {  
	const cases = [2, 0, 1, 1, 1, 2];  
	return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

//Развернуть приложение во весь экран
export function fullScreen() {
	let elem = document.querySelector("html");
	if (!document.fullscreenElement) {
		elem.requestFullscreen().catch(err => {
			devLog(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`)
		});
	} else {
		document.exitFullscreen();
  	}
}

//проверяет есть ли ключ в объекте, если есть возвращает его значение.
export function isKeyInObj(obj, key) {
	//
	if (obj) {
		if (typeof obj[key] !== "undefined") {
			return obj[key]
		} else {
			return false
		}
	} else {
		return false
	}
}

//приводим формат даты
export function dateToFromNowDaily(myDate = 0) {
	myDate = moment.unix(myDate)

	let ddmm = myDate.format("DD.MM.gggg")
	let hhmm =  myDate.format("HH:mm")
		
	return myDate.calendar( null, {
		lastDay:  function () {
			return `Вчера в ${hhmm}`
		},
		sameDay:  function () {
			return `Сегодня в ${hhmm}`
		},
		lastWeek: function () {
			return `${ddmm} в ${hhmm}`
		},
		sameElse: function () {
			return `${ddmm} в ${hhmm}`
		},
	});
}

export function setLocalStorage(key, val) {
	try {
		if (typeof window.localStorage !== 'undefined') {
			localStorage.setItem(key, val);
		} else {
			console.log('localStorage не поддерживается')
		}
	} catch (e) {
		console.log(e)
	}
}

export function getLocalStorage(key) {
	try {
		if (typeof window.localStorage !== 'undefined') {
			let item  = localStorage.getItem(key)
			return item;
		} else {
			return false;
		}
	} catch (e) {
		console.log(e)
		return false;
	}
}

export function findObjectById(arr, _id) {
	const obj = arr.find(({id}) => {
		return (id === _id) ? true : false
	})
	if (obj) {
		return obj
	} else {
		return false
	}
}

export function findObjectIndex(arr, _id) {
	const index = arr.findIndex(({id}) => {
		return (id === _id) ? true : false
	})
	if (index !== -1) {
		return index
	} else {
		return false
	}
}
