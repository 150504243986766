import React from 'react';
//import { Button } from '@vkontakte/vkui';

import './style.css';

class CardOutline extends React.Component {
	render() {
		let { className, img, title, subscribers, description, amount, goProduct, id } = this.props
		return (
			<div data-id={id} onClick={goProduct} className={`${(className) ? className : ""} _cardOutlineItem`}>
				<div>
					<div className="flex_center">
						<img className="__CardOutlineImageAvatar" width="100" loading="lazy" alt={title} src={img}/>
					</div>
					<div className="_cardOutlineItemTitle"><b>{(title.length > 35) ? `${title.substr(0,35).trim()}...` : title}</b></div>
					<div className="_cardOutlineItemSubscriber">{subscribers}</div>
					<div className="_cardOutlineItemDescription">{description}</div>

					<div className="_cardOutlineItemAmount">{amount}</div>
					{
						/*
							<div className="flex_center pt-5">
								<Button data-id={id} onClick={goProduct}>Подробнее</Button>
							</div>
						*/
					}
				</div>
			</div>
		);
	}
}

export default CardOutline;