import React from 'react';
import { Div, Cell, InfoRow, Link } from '@vkontakte/vkui';
import Number from '../Number/Number';
import PhotoGalery from '../PhotoGalery/PhotoGalery';
import { dateToFromNowDaily } from '../../func/utils';

import './style.css';

import Icon24Users from '@vkontakte/icons/dist/24/users';
import Icon24MoneyCircle from '@vkontakte/icons/dist/24/money_circle';
import Icon24Coins from '@vkontakte/icons/dist/24/coins';
import Icon24Poll from '@vkontakte/icons/dist/24/poll';
import Icon24Comment from '@vkontakte/icons/dist/24/comment';
import Icon24Gallery from '@vkontakte/icons/dist/24/gallery';

class GroupBody extends React.Component {
	render() {
		let { groupInfo, target, userInfo } = this.props
		return (
			<div>
				<Cell className="OtherCellStyle" before={<Icon24Users fill="#202EAE" />}>
					<InfoRow title="Количество подписчиков">
						<Number type="separation" number={groupInfo.members_count} text={['подписчик', 'подписчика', 'подписчиков']}/>
					</InfoRow>
				</Cell>
				<Cell before={<Icon24MoneyCircle fill="#0D8720" />}>
					<InfoRow title="Стоимость">
						<Number type="separation" number={target.amount} text={['рубль', 'рубля', 'рублей']}/>
					</InfoRow>
				</Cell>
				<Cell before={<Icon24Coins fill="#843AA7" />}>
					<InfoRow title="Доход в месяц">
						<Number type="separation" number={target.income} text={['рубль', 'рубля', 'рублей']}/>
					</InfoRow>
				</Cell>
				<Cell before={<Icon24Poll fill="#FAC817" />}>
					<InfoRow title="Ссылка на статистику">
						<Link href={`https://vk.com/stats?gid=${target.gid}`} target="_blank">{`https://vk.com/stats?gid=${target.gid}`}</Link>
					</InfoRow>
				</Cell>

				{
					(target.other_information) ?
						<Cell multiline={true} before={<Icon24Comment fill="#FA3105" />}>
							<InfoRow title="Дополнительная информация">
								<div>{target.other_information}</div>
							</InfoRow>
						</Cell>	
					: null
				}

				{
					(target.photos.length > 0 && target.ad_network) ?
						<Cell before={<Icon24Gallery fill="#0296D6" />}>
							<InfoRow title="Скины из рекламной сети">
								<PhotoGalery photos={target.photos}/>
							</InfoRow>
						</Cell>
					: null
				}
				<Div className="flex_end pt-0">
					<InfoRow className="OtherInfoRowStyleTitle" title="Продавец">
						<Link href={`https://vk.com/id${userInfo.id}`} target="_blank">{userInfo.first_name} {userInfo.last_name}</Link>
					</InfoRow>
				</Div>
				<Div className="flex_end pt-0">
					<InfoRow className="OtherInfoRowStyleTitle" title="Дата публикации">
						{dateToFromNowDaily(target.time)}
					</InfoRow>
				</Div>
			</div>
		);
	}
}

export default GroupBody;