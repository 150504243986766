import app from './app';
import config from '../config';
import { getSystemAlbumId, setSystemAlbumId, uploadPhoto } from './back';
import { photosGetAlbums, photosCreateAlbum, photosGetUploadServer, photosSave } from './generate/methods/photos';

export function upload(statusName, file, callback) {
	app.setState({ [statusName]: 10 })
	getSystemAlbumId(({aid}) => {
		testAid(aid, (isValid) => {
			app.setState({ [statusName]: 20 })
			if (isValid) {
				//альбом точно есть
				uploadInAlbum(statusName, file, aid, (jsonFile) => {
					app.setState({ [statusName]: 100 })
					callback(jsonFile)
				})
			} else {
				//такого альбома уже нет
				photosCreateAlbum(config.access_token, {
					title: "Магазин сообществ",
					description:"Альбом для фотографий в приложении магазин сообществ ",
					privacy_view:"only_me",
				}, (response) => {
					const { id } = response
					setSystemAlbumId(id, (ok) => {
						if (ok) {
							//Альбом удачно записан
							uploadInAlbum(statusName, file, id, (jsonFile) => {
								app.setState({ [statusName]: 100 })
								callback(jsonFile)
							})
						} else {
							console.log('Ошибка создания альбома')
						}
					})
				})
			}
		})
	})
}

function uploadInAlbum(statusName, file, aid, callback) {
	app.setState({ [statusName]: 30 })
	photosGetUploadServer(config.access_token, {
		album_id: aid
	}, (UploadServer) => {
		app.setState({ [statusName]: 50 })
		const { upload_url } = UploadServer
		uploadPhoto(file, upload_url, (response) => {
			app.setState({ [statusName]: 70 })
			photosSave(config.access_token, {
				album_id: response.aid,
				server: response.server,
				photos_list: response.photos_list,
				hash: response.hash,
			}, (isSaved) => {
				app.setState({ [statusName]: 80 })
				callback(isSaved)
			})
		})
	})
}

function testAid(aid, callback) {
	photosGetAlbums(config.access_token, {
		owner_id: +config.params.vk_user_id,
		album_ids: aid,		
	}, (response) => {
		callback(findAlbum(aid, response.items))
	})
}

function findAlbum(myaid, obj) {
	if (obj.length > 0) {
		let test = obj.findIndex((item) => { if (Number(item.id) === Number(myaid)) { return true } else { return false } });
		if (test !== -1) {
			return true
		} else {
			return false
		}			
	} else {
		return false
	}
}