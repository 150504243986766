import React from 'react';
import { Placeholder } from '@vkontakte/vkui';

import './style.css';

import Icon56MarketOutline from '@vkontakte/icons/dist/56/market_outline';

class InfoPlaceholder extends React.Component {
	render() {
		const { title, text, action, icon, stretched } = this.props
		return (
			<Placeholder
				icon={(icon) ? icon : <Icon56MarketOutline />}
				title={title}
				action={action}
				stretched={(stretched) ? true : false}
			>
				{text}
			</Placeholder>
		);
	}
}

export default InfoPlaceholder;