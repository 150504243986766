import React from 'react';
import { Panel, Spinner, Button, Div } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import GroupHeader from '../components/GroupHeader/GroupHeader';
import GroupBody from '../components/GroupBody/GroupBody';
import InfoPlaceholder from '../components/InfoPlaceholder/InfoPlaceholder';

import app from '../func/app';
import config from '../config';

import { showAnswerMessage, showInfoMessage } from '../func/alert';
import { findObjectById, findObjectIndex } from '../func/utils';
import { delProduct, buyProduct } from '../func/back';

import Icon56InfoOutline from '@vkontakte/icons/dist/56/info_outline';

class ProductView extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			target: false,
			groupInfo: false,
			userInfo: false,
			buy_disabled: false,
		};
	}

	componentDidMount() {
		const { apiUsersData, apiGroupsData, feed, feedTarget } = this.props
		
		let target = findObjectById(feed.items, feedTarget)
		let groupInfo = findObjectById(apiGroupsData, target.gid)
		let userInfo = findObjectById(apiUsersData, target.uid)

		this.setState((state) => ({
			target: target,
			groupInfo: groupInfo,
			userInfo: userInfo,
		}))
	}

	onDeleteProduct = () => {
		const { feedTarget, navigator } = this.props
		showAnswerMessage('Удалить?', 'Вы действительно хотите удалить это объявление?', (yes) => {

			//пока асинхронно
			delProduct(+feedTarget, () => {})

			app.setState((state) => {
				const index = findObjectIndex(state.feed.items, +feedTarget)
				const myIndex = findObjectIndex(state.myFeed.items, +feedTarget)
			
				const myTempId = state.myTempEditProduct.id || false
				const tempId = state.tempEditProduct.id || false

				return (
					{
						feed: {
							...state.feed,
							items: (index !== false) ? [...state.feed.items.slice(0, index), { ...state.feed.items[index], deleted: true }, ...state.feed.items.slice(index + 1)] : [...state.feed.items]
						},
						myFeed: {
							...state.myFeed,
							items: (myIndex !== false) ? [...state.myFeed.items.slice(0, myIndex), { ...state.myFeed.items[myIndex], deleted: true }, ...state.myFeed.items.slice(myIndex + 1)] : [...state.myFeed.items ]
						},
						//выбранные продукты тоже изменяем если они выбраны
						myTempEditProduct: (myTempId === +feedTarget) ? { ...state.myTempEditProduct, deleted: true } : { ...state.myTempEditProduct },
						tempEditProduct: (tempId === +feedTarget) ? { ...state.tempEditProduct, deleted: true } : { ...state.tempEditProduct }
					}
				)

			}, () => {
				navigator.goPanelBack()
			})

		})
	}

	onEditProduct = () => {
		const { navigator, apiGroupsData, feed, feedTarget, names } = this.props

		let target = findObjectById(feed.items, feedTarget)
		let groupInfo = findObjectById(apiGroupsData, target.gid)
		
		app.setState((state) => ({
			[names[0]]: groupInfo,
			[names[1]]: {
				...state[names[1]],
				...target
			}
		}), () => {
			navigator.goPanel("EditProduct")
		})
	}

	onBuyProduct = () => {
		const { feedTarget } = this.props
		this.setState({
			buy_disabled: true
		}, () => {
			buyProduct(+feedTarget, (response) => {
				app.setState((state) => ({
					shoppingCart: [...state.shoppingCart, +feedTarget]
				}), () => {
					showInfoMessage("Отлично!", "Ваша заявка принята. Скоро с вами свляжется наш администратор.")
				})
			})
		})
	}

	render() {
		const { target, groupInfo, userInfo, buy_disabled } = this.state
		const { id, navigator, admin_notify, shoppingCart, feedTarget } = this.props
		const { admin_app, params } = config
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigator.goPanelBack } name="Объявление"/>
				{
					(target && groupInfo && userInfo) ?
						(target.deleted) ? 
							<InfoPlaceholder
								icon={<Icon56InfoOutline/>}
								title="Упс..."
								text="Похоже Вы удалили это объявление"
								action={
									<Div className="flex_center pt-0">
										<Button onClick={navigator.goPanelBack}> 
											В каталог
										</Button>
									</Div>
								}
							/>
						: 
							<div>
								<GroupHeader 
									target={target}
									groupInfo={groupInfo}
									actionBlock={
										<Div className="flex_end pt-0">
											{
												(admin_app.indexOf(+params.vk_user_id) !== -1 || target.uid === +params.vk_user_id || admin_notify.indexOf(+params.vk_user_id) !== -1) ?
													<div>
														<Button onClick={this.onEditProduct} level="secondary" className="mr-5">Редактировать</Button>
														<Button onClick={this.onDeleteProduct}>Удалить</Button>
													</div>
												: 
													//пользователь уже покупал это сообщество за эту сессию
													(shoppingCart.indexOf(+feedTarget) === -1) ?
														<div>
															<Button disabled={buy_disabled} onClick={this.onBuyProduct}>Купить</Button>
														</div>
													: null
													
											}
										</Div>
									}
								/>
								<GroupBody
									target={target}
									groupInfo={groupInfo}
									userInfo={userInfo}
								/>
							</div>
					: <Spinner size="regular" style={{ marginTop: 20 }} />
				}
			</Panel>
		)
	}
}

export default ProductView;