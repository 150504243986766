//Этот файл сгенерирован автоматически
import { apiRequest } from '../../vkApiRequest';


//Checks whether a link is blocked in VK.
//user,group,service
export function utilsCheckLink(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("utils.checkLink",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Deletes shortened link from user's list.
//user
export function utilsDeleteFromLastShortened(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("utils.deleteFromLastShortened",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of user's shortened links.
//user
export function utilsGetLastShortenedLinks(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("utils.getLastShortenedLinks",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns stats data for shortened link.
//user,group,service
export function utilsGetLinkStats(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("utils.getLinkStats",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns the current time of the VK server.
//user,group,service
export function utilsGetServerTime(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("utils.getServerTime",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allows to receive a link shortened via vk.cc.
//user,group,service
export function utilsGetShortLink(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("utils.getShortLink",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Detects a type of object (e.g., user, community, application) and its ID by screen name.
//user,group,service
export function utilsResolveScreenName(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("utils.resolveScreenName",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}
