import config from '../config';
import 'whatwg-fetch'
import { showErrorMessage } from './alert';

export function sentReq(json_data, callback = () => {}, callback_error = () => {}) {
	let url = config.backUrl
	json_data.params = config.params
	
	fetch(url, {
		method: 'post',
		headers: {
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: 'data=' + encodeURIComponent(JSON.stringify(json_data))
	})
	.then(function (response) {
		return response.json();
	})
	.then((data) => {
		callback(data);
	})
	.catch(function (error) {
		callback_error(error)
	});
}

export function sentFile(json_data, file, callback = () => {}, callback_error = () => {}) {
	let formData = new FormData();

	for (let key in json_data) {
		formData.append(key, json_data[key]);
	}
	formData.append('file', file);

	fetch(config.uploadUrl, {
	  method: 'POST',
	  body: formData
	})
	.then(function (response) {
		return response.json();
	})
	.then((data) => {
		callback(data);
	})
	.catch(function (error) {
		callback_error(error)
	});
}

export function sentWss(connection, json_data) {
	json_data.params = config.params
	connection.send(JSON.stringify(json_data));
}

/*
	запросы для получения данных
*/
//первый запрос на получение всех данных
export function firstQuery(callback = () => {}) {
	//тут первые запросы к апи
	sentReq({command: "firstQuery"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			showErrorMessage("Ошибка", text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

//получение каталога
export function getProducts(callback = () => {}) {
	//тут первые запросы к апи
	sentReq({command: "getProducts"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			showErrorMessage("Ошибка", text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

//получение моих продуктов
export function getMyProducts(callback = () => {}) {
	//тут первые запросы к апи
	sentReq({command: "getMyProducts"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			showErrorMessage("Ошибка", text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

/*
	запросы для управления продуктом
*/

//покупка
export function buyProduct(id, callback = () => {}) {
	sentReq({
		command: "buyProduct",
		id: id
	}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			showErrorMessage("Ошибка", text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

//создаёт новый продукт
export function setProduct(gid, amount, activity, income, ad_network, photos, other_information, members_count, callback = () => {}) {
	sentReq({
		command: "setProduct",
		gid: gid,
		amount: amount,
		activity: activity,
		income: income,
		ad_network: ad_network,
		photos: photos,
		other_information: other_information,
		members_count: members_count,
	}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			showErrorMessage("Ошибка", text_error)
		}
	}, (error) => {
		console.log(error)
	})
}


//редактирует продукт
export function editProduct(id, gid, amount, activity, income, ad_network, photos, other_information, members_count, callback = () => {}) {
	sentReq({
		command: "editProduct",
		id: id,
		gid: gid,
		amount: amount,
		activity: activity,
		income: income,
		ad_network: ad_network,
		photos: photos,
		other_information: other_information,
		members_count: members_count,
	}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			showErrorMessage("Ошибка", text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

//удаляет продукт
export function delProduct(id, callback = () => {}) {
	sentReq({
		command: "delProduct",
		id: id,
	}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			showErrorMessage("Ошибка", text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

/*
	запросы для управления админами
*/

//добавляет администратора
export function setAdmin(uid, callback = () => {}) {
	sentReq({
		command: "setAdmin",
		uid: uid
	}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			showErrorMessage("Ошибка", text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

//удаляет администратора
export function delAdmin(uid, callback = () => {}) {
	sentReq({
		command: "delAdmin",
		uid: uid
	}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			const { text_error } = response
			showErrorMessage("Ошибка", text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

/*
	запросы для управления фотографиями
*/

export function uploadPhoto(file, upload_url, callback) {
	sentFile({upload_url:upload_url, params:JSON.stringify(config.params)}, file, (response) => {
		callback(response)
	}, (error) => {
		console.log(error)
	})
}

export function getSystemAlbumId(callback = () => {}) {
	sentReq({command: "getSystemAlbumId"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			console.log(response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function setSystemAlbumId(aid, callback = () => {}) {
	sentReq({command: "setSystemAlbumId", aid:aid}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			console.log(response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}
