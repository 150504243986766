import app from './app';
import config from '../config';
import { getParams, getHash, isKeyInObj, setLocalStorage, getLocalStorage } from './utils';
import { getUserInfo, getStartToken, listenVKWebAppUpdateConfig, apiGetGroupById, apiGetUser } from './vk';

import { firstQuery as back_firstQuery } from './back';


export function go(callback) {

	listenVKWebAppUpdateConfig((data) => {
		let scheme = data.scheme ? data.scheme : 'client_light';
		app.setState({
			scheme: scheme
		})
	})

	const params = getParams()
	const hash = getHash()

	config.set({
		params: params,
		hash: hash,
		app_id: +params.vk_app_id
	}, () => {
		getUserInfo((userData) => {
			getStartToken(params.vk_access_token_settings.split(','), (token) => {
				console.log(token)
				startApp(userData, token, false)
			})
		})
	})
}

export function startApp(userData, token, ignoreStartPanel = false) {
	if (ignoreStartPanel) { //fix 21.10.2019 чтобы не получать теже самые данные 2 раза
		app.setState({
			goStartPanel: false
		}, () => {
			setLocalStorage('welcome', 'true')
		})
	} else {
		const { hash } = config
		const params = {
			vk_group_id: (isKeyInObj(hash, 'g')) ? +hash.g : (isStartInGroup()) ? +config.params.vk_group_id : false,
			feedid: +isKeyInObj(hash, 'f'),
		}

		config.set({
			access_token: (token) ? token.access_token : config.access_token, 
			userData: (userData) ? userData : config.userData
		}, () => {
			firstQuery(params, (token) ? token.access_token : config.access_token, (response) => {
				app.setState((state, props) => ({
					userScope: (token) ? token.scope.split(',') : state.userScope,
					goStartPanel: (ignoreStartPanel) ? 
										false 
									: 
										(isStartPanel()) ? 
											(params.vk_group_id) ? false : true
										:
											(token) ? false : (params.vk_group_id) ? false : true,
					isLoad: true,
					header: (config.params.vk_platform === "desktop_web" && config.desktopHeader) ? false : true,
					isStartInGroup: (params.vk_group_id) ? true : isStartInGroup(),
					isAdminInGroup: isAdminInGroup(),
					...response
					}), () => {
						if (ignoreStartPanel) {
							setLocalStorage('welcome', 'true')
						}
					})
			})
		})		
	}

}

function firstQuery(params = {}, access_token = '', callback = () => {}) {
	//тут все запросы к беку и все запросы к апи вк

	back_firstQuery((response) => {

		const { admin_notify, feed, myFeed } = response

		let gids = [config.info_group_id]
		let uids = [...config.admin_app]

		feed.items.forEach(({gid, uid}) => {
			if (gids.findIndex((item) => {return (gid === item)}) === -1) {
				gids.push(gid)
			}
			if (uids.findIndex((item) =>{return (uid === item)}) === -1) {
				uids.push(uid)
			}
		})
		myFeed.items.forEach(({gid, uid}) => {
			if (gids.findIndex((item) =>{return (gid === item)}) === -1) {
				gids.push(gid) 
			}
			if (uids.findIndex((item) =>{return (uid === item)}) === -1) {
				uids.push(uid)
			}
		})

		admin_notify.forEach((admin) => {
			if (uids.findIndex((item) =>{return (admin === item)}) === -1) {
				uids.push(admin)
			}
		})

		apiGetGroupById(access_token, gids, (apiGroupsData) => {
			apiGetUser(access_token, uids, (apiUsersData) => {
				callback({
					apiGroupsData: apiGroupsData,
					apiUsersData: apiUsersData,
					admin_notify: admin_notify,
					feed: feed,
					myFeed: myFeed
				})
			})
		})

	})

}

function isAdminInGroup() {
	return (config.params.vk_viewer_group_role === 'admin') ? true : false
}

function isStartPanel() {
	//проверить localstorage
	if (isStartInGroup()) {
		return false
	} else {
		let welcome = getLocalStorage('welcome')
		if (!welcome && config.startPanel) {
			return true
		} else {
			return false
		}
	}
}

function isStartInGroup() {
	return (config.params.vk_group_id) ? true : false
}