import React from 'react';
import { Panel, Button, Div, PullToRefresh } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import InfoPlaceholder from '../components/InfoPlaceholder/InfoPlaceholder';
import GroupsBlock from '../components/GroupsBlock/GroupsBlock';
import Pagination from '../components/Pagination/Pagination';

import app from '../func/app';
import config from '../config';
import { getAddScope, getMissingGroups, getMissingUsers } from '../func/vk';
import { getMyProducts } from '../func/back';

import Icon24Add from '@vkontakte/icons/dist/24/add';

class MyProducts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			disabled: false,
			fetching: false,
		};
	}

	addProduct = () => {
		const { userScope, navigator } = this.props
		this.setState({
			disabled: true
		}, () => {
			if (userScope.indexOf("groups") !== -1) {
				navigator.goPanel("GroupsList")
			} else {
				//получаем нужные права
				getAddScope(["groups"], (response) => {
					const { access_token, scope } = response
					if (scope.split(',').indexOf("groups") !== -1) {
						config.set({
							access_token: access_token
						},() => {
							app.setState({
								userScope: scope.split(',')
							}, ()=> {
								navigator.goPanel("GroupsList")
							})
						})
					} else {
						console.log("нет прав")
						this.setState({ disabled: false })
					}
				}, () => {
					console.log("нет прав")
					this.setState({ disabled: false })
				})
			}
		})
	}

	onTarget = (e, item) => {
		const { navigator } = this.props
		const { id } = item

		app.setState(() => ({
			myFeedTarget: +id
		}), () => {
			navigator.goPanel("ProductView")
		})
	}

	showNextPage = (callback) => {
		app.setState((state) => ({
			myProductsInStr: state.myProductsInStr + 15
		}), callback)
	}

	get sortItems() {
		let { myItems } = this.props
		return myItems.filter(({deleted}) => {
				if (!deleted) {
					return true
				} else {
					return false
				}
			})
	}

	onRefresh = () => {
		const { apiUsersData,  apiGroupsData} = this.props
		this.setState({
			fetching: true,
		}, () => {
			getMyProducts((response) => {
				
				const { myFeed } = response
				const availableGids = apiGroupsData.map(({id}) => { return id })
				const availableUids = apiUsersData.map(({id}) => { return id })
				const needGids = myFeed.items.map(({gid}) => { return gid })
				const needUids = myFeed.items.map(({uid}) => { return uid })

				getMissingUsers(availableUids, config.access_token, needUids, (apiUsersData) => {
					getMissingGroups(availableGids, config.access_token, needGids, (apiGroupsData) => {
						app.setState((state) => ({
							myFeed: {...myFeed},
							myProductsInStr: 15, //стандартное количество отображаемых продуктов на странице
							apiGroupsData: (apiGroupsData) ? [...state.apiGroupsData, ...apiGroupsData] : [...state.apiGroupsData], //новые данные по группам, если они вообще есть
							apiUsersData: (apiUsersData) ? [...state.apiUsersData, ...apiUsersData] : [...state.apiUsersData], //новые данные по пользователям, если они вообще есть
						}), () => {
							this.setState({fetching: false})
						})
					})
				})

			})
		})
	}

	render() {
		const { disabled, fetching } = this.state
		const { id, apiGroupsData, myProductsInStr } = this.props

		return (
			<Panel theme="white" id={id}>
				<Header name="Мои объявления"/>
				<PullToRefresh onRefresh={this.onRefresh} isFetching={fetching}>
					<div>
						{
							(this.sortItems.length > 0) ?
								<div>
									{
										(config.params.vk_platform === "desktop_web") ?
											<Div className="pb-0">
												<b>Мои объявления</b>
											</Div>
										: null
									}
									<Div className="flex_end">
										<Button disabled={disabled} onClick={this.addProduct}> 
											<div className="flex_center">
												<Icon24Add width="16" height="16" className="pr-5"/>
												ДОБАВИТЬ
											</div>
										</Button>
									</Div>

									<GroupsBlock 
										description="activity" 
										apiGroupsData={apiGroupsData} 
										inStr={myProductsInStr} 
										onTarget={this.onTarget} 
										items={this.sortItems}
									/>

									<Pagination count={this.sortItems.length} navCount={myProductsInStr} navOffset={0} showNextPage={this.showNextPage}/>
								</div>
							: 
								<InfoPlaceholder
									title="Мои объявления"
									text="Добавьте сообщество, которое Вы хотите продать"

									action={
										<Div className="flex_center pt-0">
											<Button disabled={disabled} onClick={this.addProduct}> 
												<div className="flex_center">
													<Icon24Add width="16" height="16" className="pr-5"/>
													ДОБАВИТЬ
												</div>
											</Button>
										</Div>
									}
								/>
						}
					</div>
				</PullToRefresh>


			</Panel>
		)
	}
}

export default MyProducts;